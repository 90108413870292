export default (...objects) => {
  // Check inputs are all objects
  let inputValid = true;
  objects.forEach(object => {
    if (typeof object !== `object`) inputValid = false;
    if (object === null) inputValid = false;
    if (object === undefined) inputValid = false;
  });
  if (!inputValid) return false;

  const allKeys = objects.reduce(
    (keys, object) => keys.concat(Object.keys(object)),
    []
  );
  const union = new Set(allKeys);
  return objects.every(object => union.size === Object.keys(object).length);
};
