import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import styles from "./index.module.css";

import SaveContainer from "../Save/container";

import { ShareLinkedIn, ShareTwitter } from "../Share";

const ArticleHeaderWithoutStaticQuery = ({
  title,
  titleNumber,
  entityId,
  siteUrl,
  path
}) => {
  const shareUrl = `${siteUrl}${path}`;

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.titleBlock}>
          <h2 className={styles.titleHeader}>
            <span className={styles.titleNumber}>{titleNumber}</span>
            <div className={styles.headerActions}>
              <SaveContainer withText={true} entityId={entityId} />
              <div className={styles.shareIcon}>
                <ShareLinkedIn
                  className={styles.shareIcon}
                  url={`${siteUrl}`}
                  sourceUrl={shareUrl}
                  title={title}
                  large={true}
                />
              </div>
              <div className={styles.shareIcon}>
                <ShareTwitter text={title} url={shareUrl} large={true} />
              </div>
            </div>
          </h2>
          <h1 className={styles.title}>{title}</h1>
        </div>
      </div>
    </div>
  );
};

// TODO: run this query directly from the Share component instead of here?
export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteUrl: url
          }
        }
      }
    `}
    render={data => (
      <ArticleHeaderWithoutStaticQuery
        siteUrl={data.site.siteMetadata.siteUrl}
        {...props}
      />
    )}
  />
);

ArticleHeaderWithoutStaticQuery.propTypes = {
  titleNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  siteUrl: PropTypes.string,
  path: PropTypes.string
};
