import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import Img from "gatsby-image";

// TODO: add styles for narrow image type
const BlockImage = ({ imageImage, description, type }) => {
  // maybe the original was in a funny format?

  if (!imageImage || !imageImage.childImageSharp) return null;
  return (
    <div className={type === `narrow` ? styles.rootNarrow : styles.root}>
      <div className={styles.content}>
        <Img
          className={styles.image}
          alt={description}
          fluid={imageImage.childImageSharp.fluid}
        />
      </div>
    </div>
  );
};

BlockImage.defaults = {
  type: `fullwidth`
};

BlockImage.propTypes = {
  imageImage: PropTypes.shape({}),
  description: PropTypes.string,
  type: PropTypes.oneOf([`fullwidth`, `narrow`]).isRequired
};

export default BlockImage;
