import { connect } from "react-redux";
import SavedArticlesPanel from "../SavedArticlesPanel";

import { getSavedArticleIds, setValidIds } from "../../../state/articles/save";

const withRedux = connect(
  state => ({
    savedArticleIds: getSavedArticleIds(state)
  }),
  dispatch => ({
    setValidIds(validIds) {
      dispatch(setValidIds(validIds));
    }
  })
);

export default withRedux(SavedArticlesPanel);
