import React from "react";
import PropTypes from "prop-types";

import PrimaryButton from "../PrimaryButton";
import styles from "./index.module.css";

const Footer = ({ reportPath }) => (
  <div className={styles.root}>
    <div className={styles.inner}>
      <p className={styles.text}>It truly has been a great year for Europe.</p>
      <div className={styles.nav}>
        <PrimaryButton to={reportPath} type="link">
          Read the full report
        </PrimaryButton>
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  reportPath: PropTypes.string
};

export default Footer;
