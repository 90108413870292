import React from "react";
import PropTypes from "prop-types";

/**
 * An SVG icon. Stroke and fill can be supplied as props OR applied via CSS
 * using the className prop.
 *
 * @param {string} className  A classname to be applied to the element
 * @param {string} stroke     A colour
 * @param {string} fill       A colour
 */
const SaveIcon = ({ className, stroke, fill }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
      >
        <polygon
          stroke={stroke}
          fill={fill}
          points="292.482 2 295.256 6.329 299.964 7.812 296.971 11.971 297.106 17.217 292.482 15.459 287.858 17.217 287.993 11.971 285 7.812 289.708 6.329"
          transform="translate(-284 -2)"
        />
      </svg>
    </span>
  );
};

SaveIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string
};

export default SaveIcon;
