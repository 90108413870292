import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

const Block = ({ backgroundColor, children, fullBleed, tightPadding, id }) => {
  if (fullBleed) return children;
  const contentStyle = tightPadding ? styles.contentTight : styles.content;
  return (
    <div id={id} className={styles.root} style={{ backgroundColor }}>
      <div className={contentStyle}>{children}</div>
    </div>
  );
};

Block.defaultProps = {
  fullBleed: false
};

Block.propTypes = {
  id: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  fullBleed: PropTypes.bool,
  tightPadding: PropTypes.bool
};

export default Block;
