import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import PrimaryButton from "../PrimaryButton";

import { modals, chartDownloadTypes } from "../../state/modal";

class DownloadForm extends Component {
  constructor(props) {
    super(props);
    const modalProps = props.modal.modalProps || {};
    this.state = {
      email: this.props.user.email || ``,
      newsletter: this.props.user.newsletter === true ? true : false,
      termsAgreed: this.props.user.termsAgreed || false,
      isSubmitted: false,
      isDownloadChart: props.modal.modalType == modals.DOWNLOAD_CHART,
      isDownloadReport: props.modal.modalType == modals.DOWNLOAD_REPORT,
      disabledDataDownload: modalProps.disabledDataDownload || false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.download = this.download.bind(this);
    this.downloadImage = this.downloadImage.bind(this);
    this.downloadData = this.downloadData.bind(this);
  }

  handleInputChange(e) {
    const target = e.target;
    const value = target.type === `checkbox` ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  download(e) {
    // Default download button action
    this.setState({ isSubmitted: true });
    e.preventDefault();
    this.props.onSubmit(this.state);
  }

  downloadImage(e) {
    this.setState({ isSubmitted: true });
    e.preventDefault();
    this.props.onSubmit(this.state, chartDownloadTypes.CHART_DOWNLOAD_IMAGE);
  }

  downloadData(e) {
    this.setState({ isSubmitted: true });
    e.preventDefault();
    this.props.onSubmit(this.state, chartDownloadTypes.CHART_DOWNLOAD_DATA);
  }

  render() {
    const {
      email,
      termsAgreed,
      newsletter,
      isSubmitted,
      isDownloadChart,
      isDownloadReport,
      disabledDataDownload
    } = this.state;
    const disabled =
      termsAgreed === false || email.length < 5 || !email.includes(`@`);

    return (
      <div className={styles.root}>
        {isDownloadChart && (
          <React.Fragment>
            <h1 className={styles.title}>Download Chart</h1>
            {!disabledDataDownload && (
              <p className={styles.body}>
                {`Enter an email below to continue to download the chart. You'll only need to enter it once, unless you delete your cookies. You can download the raw data or the chart image as displayed on this site.`}
              </p>
            )}
            {disabledDataDownload && (
              <p className={styles.body}>
                {`Enter an email below to continue to download the chart. You'll only need to enter it once, unless you delete your cookies. You can download the chart image as displayed on this site.`}
              </p>
            )}
          </React.Fragment>
        )}
        {isDownloadReport && (
          <React.Fragment>
            <h1 className={styles.title}>Download Report</h1>
            <p className={styles.body}>
              {`Enter an email below to continue to download the file. You'll only need to enter it once, unless you delete your cookies.`}
            </p>
          </React.Fragment>
        )}
        <form className={styles.form}>
          <div className={styles.input}>
            <label htmlFor="email" className={styles.inputLabel}>
              Email address
              {` `}
            </label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={this.handleInputChange}
              className={styles.inputControl}
              placeholder="Enter your email address here"
            />
          </div>
          <div className={styles.checkbox}>
            <label className={styles.checkboxLabel}>
              <input
                name="termsAgreed"
                type="checkbox"
                checked={termsAgreed}
                onChange={this.handleInputChange}
                className={styles.checkboxControl}
              />
              <span style={{ display: `inline-block` }}>
                I agree to the&nbsp;
                <a
                  className={styles.link}
                  href="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms and conditions
                </a>
                {` `}
                <span className={styles.required}>(required)</span>
              </span>
            </label>
          </div>
          <div className={styles.checkbox}>
            <label className={styles.checkboxLabel}>
              <input
                name="newsletter"
                type="checkbox"
                checked={newsletter}
                onChange={this.handleInputChange}
                className={styles.checkboxControl}
              />
              Please subscribe me to the Atomico mailing list
            </label>
          </div>
          <div className={styles.submit}>
            {!isSubmitted && isDownloadChart && (
              <React.Fragment>
                {!disabledDataDownload && (
                  <div className={styles.downloadButton}>
                    <PrimaryButton
                      onClick={this.downloadData}
                      dark={true}
                      disabled={disabled}
                    >
                      Download Data
                    </PrimaryButton>
                  </div>
                )}
                <div className={styles.downloadButton}>
                  <PrimaryButton
                    onClick={this.downloadImage}
                    dark={true}
                    disabled={disabled}
                  >
                    Download Image
                  </PrimaryButton>
                </div>
              </React.Fragment>
            )}
            {!isSubmitted && !isDownloadChart && (
              <PrimaryButton
                onClick={this.download}
                disabled={disabled}
                dark={true}
              >
                Download
              </PrimaryButton>
            )}
            {isSubmitted && (
              <span className={styles.loadingText}>Loading..</span>
            )}
          </div>
        </form>
      </div>
    );
  }
}

DownloadForm.defaultProps = {
  user: {}
};

DownloadForm.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    termsAgreed: PropTypes.bool,
    newsletter: PropTypes.bool
  }),
  onSubmit: PropTypes.func,
  modal: PropTypes.shape({
    modalType: PropTypes.symbol,
    modalProps: PropTypes.shape({
      disabledDataDownload: PropTypes.bool
    })
  })
};

export default DownloadForm;
