import React from "react";
import styles from "./index.module.css";
import PropTypes from "prop-types";

const KeyFindingsContents = ({ titles }) => {
  return (
    <div className={styles.root} id="key-findings">
      <div className={styles.inner}>
        <h2 className={styles.title}>2018 Key Findings</h2>
        <div className={styles.contentCols}>
          <div className={styles.contentCol}>
            <h3 className={styles.subTitle}>
              What&rsquo;s changed for European tech in the past 12 months?
            </h3>
            <p className={styles.body}>
              It&rsquo;s been another record year for investment in European
              tech and the sector is powering growth in Europe&rsquo;s stagnant
              economy. Yet not everyone is benefitting from the boom. The gains
              are not being democratized by investors. Companies need to address
              diversity and inclusion tools and unlock hidden talent pools.
            </p>
          </div>
          <div className={styles.contentCol}>
            <div className={styles.links}>
              <h3 className={styles.linksTitle}>Key Findings</h3>
              <ol className={styles.linksList}>
                {titles.map((title, index) => (
                  <li key={index} className={styles.linksItem}>
                    <a
                      className={styles.linksLink}
                      href={`#finding-0${index + 1}`}
                    >
                      {title}
                    </a>
                  </li>
                ))}
                <li className={styles.linksItem}>
                  <a
                    className={styles.linksLink}
                    href={`#finding-0` + (titles.length + 1)}
                  >
                    Top 20 countries&#8217; highlights
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

KeyFindingsContents.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string)
};

export default KeyFindingsContents;
