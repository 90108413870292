import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import SaveContainer from "../../Save/container";
import Highlight from "../../Highlight";
import styles from "./index.module.css";

const NavArticle = ({
  entityId,
  path,
  onClick,
  isActive,
  titleNumber,
  title,
  closeReportNav
}) => {
  // const activeStyle = isActive ? { border: `1px solid green` } : {};
  return (
    <a
      className={isActive ? styles.rootActive : styles.root}
      href={path}
      onClick={
        onClick
          ? e => onClick(e, entityId)
          : e => {
              event.preventDefault();
              closeReportNav();
              navigate(path);
            }
      } // Optional custom click handler
    >
      <div className={styles.inner}>
        <div className={styles.header}>
          <h1 className={styles.number}>{titleNumber}</h1>
          <div className={styles.save}>
            <SaveContainer entityId={entityId} />
          </div>
        </div>
        <h3 className={isActive ? styles.titleActive : styles.title}>
          {title}
        </h3>
      </div>
      <Highlight isActive={isActive} progress={100} />
    </a>
  );
};

NavArticle.propTypes = {
  entityId: PropTypes.number,
  path: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  titleNumber: PropTypes.string,
  title: PropTypes.string,
  closeReportNav: PropTypes.func
};

export default NavArticle;
