import React, { Component } from "react";
import PropTypes from "prop-types";
// import PrimaryButton from "../PrimaryButton";
import SelectControl from "../SelectControl";
import dealroom from "./dealroom.png";
import styles from "./index.module.css";
import CountryHighlightsMap from "../CountryHighlightsMap";

export default class CountryHighlights extends Component {
  constructor(props) {
    super(props);
    this.state = { countryCode: `eu` };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(e) {
    const el = e.target;
    this.setState({ countryCode: el.value });
  }

  getData() {
    return this.props.data.reduce((acc, curr) => {
      acc[curr.countryCode] = curr;
      return acc;
    }, {});
  }

  getCountries() {
    return this.props.data.map(item => ({
      id: item.countryCode,
      name: item.label
    }));
  }

  render() {
    const { index } = this.props;
    const { countryCode } = this.state;
    const countries = this.getCountries();
    const data = this.getData()[countryCode];
    return (
      <div className={styles.root}>
        <div id={`finding-0${index}`} className={styles.inner}>
          <p className={styles.number}>0{index}</p>
          <div className={styles.titleBar}>
            <h2 className={styles.title}>
              Key figures for 20 top funded countries in Europe
            </h2>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.contentCols}>
              <div className={styles.contentCol}>
                <div className={styles.block}>
                  <p className={styles.blockTitle}>Key figures for:</p>
                  <SelectControl
                    size="large"
                    values={countries}
                    selectedId={countryCode}
                    onChange={this.handleSelectChange}
                  />
                </div>
                <div className={styles.country}>
                  <CountryHighlightsMap countryCode={countryCode} />
                </div>
              </div>
              <div className={styles.contentCol}>
                <div className={styles.block}>
                  <p className={styles.blockTitle}>Talent</p>
                  <div className={styles.highlight}>
                    <p className={styles.highlightText}>
                      <span className={styles.highlightValue}>
                        {data.talentDevelopers}
                      </span>
                      {/* {data.talentDevelopersSuffix && (
                        <span className={styles.highlightSuffix}>
                          {data.talentDevelopersSuffix}
                        </span>
                      )} */}
                    </p>
                    <p className={styles.highlightContext}>
                      professional developers
                    </p>
                  </div>
                  <div className={styles.supporting}>
                    {data.eu_talent2017 && (
                      <div className={styles.supportingCol}>
                        <p className={styles.supportingTitle}>2017</p>
                        <p className={styles.supportingValue}>
                          {data.eu_talent2017}
                        </p>
                      </div>
                    )}
                    {data.eu_talentUSA && (
                      <div className={styles.supportingCol}>
                        <p className={styles.supportingTitle}>USA</p>
                        <p className={styles.supportingValue}>
                          {data.eu_talentUSA}
                        </p>
                      </div>
                    )}
                    {data.talentRank && (
                      <div className={styles.supportingCol}>
                        <p className={styles.supportingTitle}>Rank in Europe</p>
                        <p className={styles.supportingValue}>
                          #{data.talentRank}
                        </p>
                      </div>
                    )}
                    {data.talentTotal && (
                      <div className={styles.supportingCol}>
                        <p className={styles.supportingTitle}>Europe Total</p>
                        <p className={styles.supportingValue}>
                          {data.talentTotal}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.block}>
                  <p className={styles.blockTitle}>Investment trends in 2018</p>
                  <div className={styles.highlight}>
                    <p className={styles.highlightText}>
                      {/* <span className={styles.highlightPrefix}>$</span> */}
                      <span className={styles.highlightValue}>
                        {data.investmentCapital}
                      </span>
                      {/* <span className={styles.highlightSuffix}>
                        {data.investmentCapitalSuffix}
                      </span> */}
                    </p>
                    <p className={styles.highlightContext}>capital invested</p>
                  </div>
                  <div className={styles.supporting}>
                    <div className={styles.supportingCol}>
                      <p className={styles.supportingTitle}># of rounds</p>
                      <p className={styles.supportingValue}>
                        {data.investmentRounds}
                      </p>
                    </div>
                    {data.investmentRank && (
                      <div className={styles.supportingCol}>
                        <p className={styles.supportingTitle}>Rank in Europe</p>
                        <p className={styles.supportingValue}>
                          {data.investmentRank}
                        </p>
                      </div>
                    )}
                    {data.eu_investmentHub && (
                      <div className={styles.supportingCol}>
                        <p className={styles.supportingTitle}>#1 Hub</p>
                        <p className={styles.supportingValue}>
                          {data.eu_investmentHub}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.contentCol}>
                <div className={styles.block}>
                  <p className={styles.blockTitle}>Largest Tech Communities</p>
                  <ol className={styles.hubList}>
                    <li className={styles.hubItem}>{data.keyHubName1}</li>
                    <li className={styles.hubItem}>{data.keyHubName2}</li>
                    <li className={styles.hubItem}>{data.keyHubName3}</li>
                  </ol>
                </div>
                <div className={styles.block}>
                  <p className={styles.blockTitle}>$B+ Companies</p>
                  <div className={styles.highlight}>
                    <p className={styles.highlightText}>
                      <span className={styles.highlightValue}>
                        {data.companies}
                      </span>
                    </p>
                    <p className={styles.highlightContext}>$B+ companies</p>
                  </div>
                  <div className={styles.supporting}>
                    <div className={styles.supportingCol}>
                      <p className={styles.supportingTitle}>2017</p>
                      <p className={styles.supportingValue}>
                        {data.companies2017}
                      </p>
                    </div>
                    {data.eu_companiesUSA && (
                      <div className={styles.supportingCol}>
                        <p className={styles.supportingTitleTowLine}>
                          # countries with a $B+ company
                        </p>
                        <p className={styles.supportingValue}>
                          {data.eu_companiesUSA}
                        </p>
                      </div>
                    )}
                    {data.companiesTotal && (
                      <div className={styles.supportingCol}>
                        <p className={styles.supportingTitle}>Europe Total</p>
                        <p className={styles.supportingValue}>
                          {data.companiesTotal}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.foot}>
            {/* {data.chartLink && (
              <div className={styles.chartLink}>
                <PrimaryButton to={data.chartLink} dark type="link">
                  See charts on {data.label}
                </PrimaryButton>
              </div>
            )} */}
            <div className={styles.metaRow}>
              <div className={styles.meta}>
                <span className={styles.metaText}>Source: </span>
                <a className={styles.metaLink} href="#">
                  <img
                    className={styles.metaLogo}
                    src={dealroom}
                    alt="dealroom.co"
                  />
                </a>
              </div>
              {/* <div className={styles.meta}>
                <span className={styles.metaText}>Share: </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CountryHighlights.propTypes = {
  data: PropTypes.array,
  index: PropTypes.number
};
