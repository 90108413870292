import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

const Highlight = ({ isActive, progress }) => {
  return (
    <div
      className={isActive ? styles.rootActive : styles.root}
      style={{ width: `${progress}%` }}
    />
  );
};

Highlight.defaultProps = {
  isActive: false,
  progress: 100
};

Highlight.propTypes = {
  isActive: PropTypes.bool,
  progress: PropTypes.number
};

export default Highlight;
