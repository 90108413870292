import React from "react";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styles from "./index.module.css";
import transitionStyles from "./transitions.module.css";

const TIMEOUT = 500; // default

/**
 * Prevent component from updating in response to url changes. This allows us to
 * keep rendering an old component long enough to play it's exit transition even
 * after the url has changed.
 */
class TransitionHandler extends React.Component {
  shouldComponentUpdate() {
    return this.props.location.pathname === window.location.pathname;
  }

  render() {
    return <div className={this.props.className}>{this.props.children}</div>;
  }
}

TransitionHandler.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

class PageTransition extends React.Component {
  render() {
    const { children, location } = this.props;

    return (
      <TransitionGroup className={styles.transitionGroup}>
        <CSSTransition
          key={location.pathname}
          timeout={TIMEOUT}
          classNames={{
            enter: transitionStyles.enterREPORT,
            enterActive: transitionStyles.enterActiveREPORT,
            exit: transitionStyles.exitREPORT,
            exitActive: transitionStyles.exitActiveREPORT
          }}
        >
          <TransitionHandler
            location={location}
            className={styles.transitionItem}
          >
            {children}
          </TransitionHandler>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

PageTransition.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default PageTransition;
