import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.css";

import get from "lodash/get";
import { PageRenderer } from "gatsby";

// Describe the different states of the results component
// This is an xstate v3 compatible state machine config (although we're not actually using xstate)
// paste config into this visualiser for an interactive diagram: https://github.com/davidkpiano/xstate#visualizer
// Refer to xstate docs and https://css-tricks.com/robust-react-user-interfaces-with-finite-state-machines/
const hitMachine = {
  id: `searchResults`,
  initial: `noHits`,
  states: {
    noHits: {
      on: {
        NEW_HITS: `loading`
      }
    },
    loading: {
      on: {
        HITS_LOADED: `loaded`,
        CLEAR_HITS: `noHits`,
        LOAD_ERROR: `error`
      }
    },
    loaded: {
      on: {
        NEW_HITS: `loading`,
        CLEAR_HITS: `noHits`
      }
    },
    error: {
      on: {
        NEW_HITS: `loading`,
        CLEAR_HITS: `noHits`
      }
    }
  }
};

const Status = ({ children }) => (
  <div className={styles.status}>
    <h1 className={styles.statusMessage}>{children}</h1>
  </div>
);

/**
 * Use Gatsby's PageRenderer component to render an list of pages in one
 * component.
 *
 */
class SearchResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hitState: hitMachine.initial,
      hits: [],
      error: null
    };

    this.transition.bind(this);
    this.loadResults.bind(this);
    this.command.bind(this);
  }

  componentDidMount() {
    if (this.props.hits.length > 0) {
      this.transition({ type: `NEW_HITS` });
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.hits) === JSON.stringify(prevProps.hits)) {
      return;
    }

    if (this.props.hits.length === 0) {
      this.transition({ type: `CLEAR_HITS` });
    } else {
      this.transition({ type: `NEW_HITS` });
    }
  }

  /* Use Gatsby's resource loader to ensure we have resources for all 'hit' paths */
  async loadResults() {
    if (get(window, `___loader.getResourcesForPathname`)) {
      try {
        await Promise.all(
          this.props.hits.map(res =>
            window.___loader.getResourcesForPathname(res)
          )
        );
      } catch (error) {
        // console.log(error)
        this.transition({ type: `LOAD_ERROR` });
      }

      // resources all loaded, we can transition to loaded state
      this.transition({ type: `HITS_LOADED` });
    }
  }

  // Transition between states, running any side effects
  transition(action) {
    const currentState = this.state.hitState;
    const nextHitState = hitMachine.states[currentState].on[action.type];
    if (nextHitState) {
      const nextState = this.command(action, nextHitState);
      this.setState({
        hitState: nextHitState,
        ...nextState
      });
    }
  }

  // Run commands to coincide with next state
  command(action, nextState) {
    switch (nextState) {
      case `noHits`:
        return { error: null };
      case `loading`:
        this.loadResults();
        return { error: null };
      case `loaded`:
        return { error: null };
      case `error`:
        return { error: true };
    }
  }

  render() {
    const { hits } = this.props;
    const { hitState } = this.state;
    return (
      <div className={styles.root}>
        {hitState === `noHits` && <Status>No results</Status>}
        {hitState === `loading` && <Status>Loading results</Status>}
        {hitState === `error` && <Status>Error loading results</Status>}
        {hitState === `loaded` &&
          hits.map(pathname => (
            <PageRenderer key={pathname} location={{ pathname }} />
          ))}
      </div>
    );
  }
}

SearchResults.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.string)
};

export default SearchResults;
