import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

const ActionButton = ({ onClick, disabled, label, large }) => {
  const attrs = {};
  const className = large ? styles.rootLarge : styles.root;
  if (disabled === true) attrs.disabled = disabled;
  return (
    <button {...attrs} className={className} onClick={onClick}>
      {label}
    </button>
  );
};

ActionButton.defaultProps = {
  label: `Download`
};

ActionButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  large: PropTypes.bool
};

export default ActionButton;
