import React from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styles from "./index.module.css";

const defaultConfig = {
  className: styles.root,
  selectedTabClassName: styles.tabSelected,
  disabledTabClassName: styles.tabDisabled,
  selectedTabPanelClassName: styles.tabPanelSelected,
  forceRenderTabPanel: true
};

const TabChrome = ({ tabs, children, config }) => {
  const tabsConfig = { ...defaultConfig, ...config };
  return (
    <Tabs {...tabsConfig}>
      <TabList className={styles.tabList}>
        {tabs.map((TabItem, i) => (
          <Tab className={TabItem.props.tabClassName || styles.tab} key={i}>
            {TabItem}
          </Tab>
        ))}
      </TabList>
      {children.map((child, i) => (
        <TabPanel className={styles.tabPanel} key={i}>
          {child}
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default TabChrome;

TabChrome.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.node),
  config: PropTypes.shape()
};
