import React from "react";
import PropTypes from "prop-types";
import TopBar from "../TopBar";
import PageTransition from "../PageTransition";

import Banner from "../Banner";
import SearchProvider from "../SearchProvider";
import ReportNav from "../ReportNav";
import ReportNavWrapper from "../ReportNav/wrapper";
import ModalRootContainer from "../ModalRoot/container";
import Onboarding from "../Onboarding";
import MenuBar from "../MenuBar";
import CookieMessageContainer from "../CookieMessage/container.js";

import "./index.css";
import styles from "./index.module.css";

const isReportRoute = path => /^(\/chapter\/|\/article\/)/.test(path);
const isSearchRoute = path =>
  path.startsWith(`/search-data`) || path.startsWith(`/search-articles`);

export class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerOnboarding: false
    };
    this.closeOnboarding = this.closeOnboarding.bind(this);
    this.openOnboarding = this.openOnboarding.bind(this);
  }

  closeOnboarding() {
    this.setState({ triggerOnboarding: false });
  }

  openOnboarding() {
    this.setState({ triggerOnboarding: true });
  }

  render() {
    const { children, location } = this.props;
    // Standalone pages, don't need no layout
    if (
      location.pathname.startsWith(`/chart/`) ||
      location.pathname.startsWith(`/content-overview`) ||
      location.pathname.startsWith(`/article/`)
    )
      return children;

    const showReportLayout =
      isReportRoute(location.pathname) || isSearchRoute(location.pathname);

    // Standard layout
    return (
      <React.Fragment>
        <Banner />
        <div className={styles.topBarContainer}>
          <TopBar toggleOnboarding={this.openOnboarding} />
        </div>
        <div className={styles.menuBar}>
          {showReportLayout && <MenuBar pathname={location.pathname} />}
        </div>
        <div className={styles.content}>
          <SearchProvider>
            {showReportLayout && (
              <ReportNavWrapper>
                <ReportNav location={location} />
              </ReportNavWrapper>
            )}
            <PageTransition location={location}>{children}</PageTransition>
          </SearchProvider>
        </div>
        <ModalRootContainer />
        <Onboarding
          trigger={this.state.triggerOnboarding}
          onClose={this.closeOnboarding}
        />
        <CookieMessageContainer />
      </React.Fragment>
    );
  }
}

Root.propTypes = {
  location: PropTypes.object
};

export default Root;
