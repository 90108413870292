/**
 * Feature test for sessionStorage and localStorage browser apis. If they are
 * present use the real modules, otherwise use noop mocks instead.
 */

const storageAvailable = type => {
  try {
    const storage = window[type];
    const x = `__storage_test__`;
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

const noop = () => null;

const noopStorage = {
  getItem: noop,
  removeItem: noop,
  setItem: noop
};

const sessionStorage = storageAvailable(`sessionStorage`)
  ? window.sessionStorage
  : noopStorage;

const localStorage = storageAvailable(`localStorage`)
  ? window.localStorage
  : noopStorage;

const storage = {
  getItem: keyName => {
    return sessionStorage.getItem(keyName) || localStorage.getItem(keyName);
  },
  removeItem: keyName => {
    localStorage.removeItem(keyName);
    sessionStorage.removeItem(keyName);
  },
  setItem: (keyName, keyValue) => {
    localStorage.setItem(keyName, keyValue);
    sessionStorage.setItem(keyName, keyValue);
  }
};

export default storage;
