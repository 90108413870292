import React from "react";
// import PropTypes from "prop-types";
import styles from "./index.module.css";

const Banner = () => (
  <div className={styles.root}>
    <span className={styles.text}>Our 2019 Report is live!</span>
    <a
      href="https://2019.stateofeuropeantech.com/"
      className={styles.button}
      rel="noopener noreferrer"
    >
      Read it here
    </a>
  </div>
);

export default Banner;
