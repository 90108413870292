import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import ActionButton from "../ActionButton";

class CookieMessage extends Component {
  constructor(props) {
    super(props);
    this.state = { shouldRender: false };
  }
  componentDidMount() {
    // componentDidMount is only called in a browser env. Don't render this
    // component on the server.
    this.setState({ shouldRender: true });
  }
  render() {
    const { acceptedCookieNotice, dismissCookieNotice } = this.props;
    const { shouldRender } = this.state;

    if (!acceptedCookieNotice && shouldRender) {
      return (
        <div>
          <div className={styles.rootShow}>
            <div className={styles.inner}>
              <span className={styles.text}>
                This website uses cookies in order to provide you with a better
                experience. To find out more about cookies&nbsp;
                <a
                  className={styles.link}
                  href="/cookies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  click here.
                </a>
              </span>
              <div className={styles.accept}>
                <ActionButton label="I accept" onClick={dismissCookieNotice} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    // default to nothing for SSR
    return null;
  }
}

CookieMessage.propTypes = {
  acceptedCookieNotice: PropTypes.bool,
  dismissCookieNotice: PropTypes.func
};

export default CookieMessage;
