import React from "react";
import styles from "./index.module.css";

class ReportLayout extends React.Component {
  /* ReportNav lives higher up the component tree, here we just have styles
     on .contentWrapper that leaves space for it
  */
  render() {
    return (
      <div className={styles.contentWrapper} id="report-content">
        {this.props.children}
      </div>
    );
  }
}

export default ReportLayout;
