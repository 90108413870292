import React from "react";
// import PropTypes from "prop-types";
import { connectSearchBox } from "react-instantsearch-dom";
import debounce from "lodash/debounce";
import searchIcon from "./searchIcon.svg";
import Svg from "../Svg";
import analytics from "../../utils/analytics";

import styles from "./index.module.css";

const SearchInput = connectSearchBox(({ refine }) => {
  const debouncedSearch = debounce(e => refine(e.target.value), 300);
  const debouncedAnalytics = debounce(analytics, 300);
  const onChange = e => {
    e.persist();
    debouncedSearch(e, e.eventTarget);
    debouncedAnalytics(`send`, `event`, `search`, `searchTerm`, e.target.value);
  };

  return (
    <div className={styles.root}>
      <label className={styles.label}>
        <input
          className={styles.input}
          placeholder="Search here..."
          type="search"
          onChange={onChange}
        />
        <Svg svg={searchIcon} alt="Search" className={styles.icon} />
      </label>
    </div>
  );
});

export default SearchInput;
