import React from "react";
import PropTypes from "prop-types";
import { Link, StaticQuery, graphql } from "gatsby";
import Svg from "../Svg";
import styles from "./index.module.css";
import logoAtomicoSmall from "./logoAtomicoSmall.svg";
import logoAtomicoLarge from "./logoAtomicoLarge.svg";
import logoSlush from "./logoSlush.svg";
import logoOrrick from "./logoOrrick.svg";
import downArrow from "./downArrow.svg";

const isActive = ({ location }) => {
  return location.pathname.startsWith(`/chapter`)
    ? { className: styles.navLinkActive }
    : null;
};

export class TopBarWithoutStaticQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMore: false };

    this.toggleTopBarMore = this.toggleTopBarMore.bind(this);
    this.closeTopBarMore = this.closeTopBarMore.bind(this);
  }

  toggleTopBarMore() {
    this.setState(prevState => ({ showMore: !prevState.showMore }));
  }

  closeTopBarMore() {
    this.setState({ showMore: false });
  }

  render() {
    const { showMore } = this.state;
    const { reportPath, toggleOnboarding } = this.props;
    const navItemMoreClass = showMore
      ? styles.navItemMoreExpanded
      : styles.navItemMore;
    return (
      <div className={styles.root}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>
            <Link className={styles.titleLink} to="/">
              <Svg
                className={styles.titleLogoSmall}
                svg={logoAtomicoSmall}
                alt="Atomico"
              />
              <Svg
                className={styles.titleLogoLarge}
                svg={logoAtomicoLarge}
                alt="Atomico"
              />
              <span className={styles.titleText}>
                <span className={styles.titleFirstLine}>The State of </span>
                European Tech
              </span>
            </Link>
          </h1>
          <div className={styles.partnership}>
            <span className={styles.partnershipText}>In Partnership with</span>
            <div className={styles.slushLogo}>
              <a
                href="http://www.slush.org"
                className={styles.logoLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Svg
                  className={styles.slushLogoImage}
                  svg={logoSlush}
                  alt="Slush logo"
                />
              </a>
            </div>
            <span className={styles.partnershipText}>&amp;</span>
            <div className={styles.orrickLogo}>
              <a
                href="https://www.orrick.com"
                className={styles.logoLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Svg
                  className={styles.orrickLogoImage}
                  svg={logoOrrick}
                  alt="Orrick logo"
                />
              </a>
            </div>
          </div>
        </div>
        <ul className={styles.nav}>
          <li className={styles.navItem}>
            <Link
              activeClassName={styles.navLinkActive}
              className={styles.navLink}
              to="#key-findings"
            >
              Key Findings
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              getProps={isActive}
              className={styles.navLink}
              to={reportPath}
            >
              2018 Report
            </Link>
          </li>
          <li className={styles.navItemCollapse}>
            <a
              className={styles.navLink}
              href="https://2017.stateofeuropeantech.com"
            >
              2017 Report
            </a>
          </li>
          <li className={styles.navItemCollapse}>
            <Link className={styles.navLink} to="/chapter/about/">
              About
            </Link>
          </li>
          <li className={styles.navItemCollapse}>
            <a
              href="#"
              className={styles.navLink}
              onClick={e => {
                e.preventDefault();
                toggleOnboarding();
              }}
            >
              Site Tips
            </a>
          </li>
          <li className={navItemMoreClass} onClick={this.toggleTopBarMore}>
            More
            <Svg className={styles.downArrow} svg={downArrow} />
          </li>
        </ul>
        {showMore && (
          <ul className={styles.moreNav} onClick={this.closeTopBarMore}>
            <li className={styles.moreNavItem}>
              <a
                className={styles.navLink}
                href="https://2017.stateofeuropeantech.com"
              >
                2017 Report
              </a>
            </li>
            <li className={styles.moreNavItem}>
              <Link
                activeClassName={styles.navLinkActive}
                className={styles.navLink}
                to="/chapter/about/"
              >
                About
              </Link>
            </li>
            <li className={styles.moreNavItem}>
              <a
                href="#"
                className={styles.navLink}
                onClick={e => {
                  e.preventDefault();
                  toggleOnboarding();
                }}
              >
                Site Tips
              </a>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

TopBarWithoutStaticQuery.propTypes = {
  reportPath: PropTypes.string,
  toggleOnboarding: PropTypes.func
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        soetChapter {
          path
        }
      }
    `}
    render={data => (
      <TopBarWithoutStaticQuery reportPath={data.soetChapter.path} {...props} />
    )}
  />
);
