module.exports = [{
      plugin: require('/home/ubuntu/frontend_build/plugins/wrap-with-context/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/ubuntu/frontend_build/plugins/tracking-soet/gatsby-browser'),
      options: {"plugins":[],"google":{"trackingId":"UA-36046017-2","anonymize":false}},
    },{
      plugin: require('/home/ubuntu/frontend_build/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"/home/ubuntu/frontend_build/src/components/Root/index.js"},
    },{
      plugin: require('/home/ubuntu/frontend_build/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
