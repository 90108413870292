import React from "react";
import PropTypes from "prop-types";
import { connectStateResults } from "react-instantsearch-dom";
import get from "lodash/get";

import ReportLayout from "../components/ReportLayout";
import SearchResults from "../components/SearchResults";

const SearchData = ({ hits }) => (
  <ReportLayout>
    <SearchResults hits={hits} />
  </ReportLayout>
);

SearchData.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.string)
};

export default connectStateResults(({ allSearchResults }) => {
  const indexName = process.env.GATSBY_ALGOLIA_CHART_INDEX;
  const hits = get(allSearchResults, `${indexName}.hits`, []).map(
    hit => `/chart/${hit.chartId}-${hit.group.id}/`
  );

  return <SearchData hits={hits} />;
});
