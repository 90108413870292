import { connect } from "react-redux";
import DownloadForm from "./";

import { getModal, requestDownload } from "../../state/modal";
import { getUser } from "../../state/user";

const withRedux = connect(
  state => ({
    user: getUser(state),
    modal: getModal(state)
  }),
  dispatch => ({
    onSubmit(values, downloadType) {
      dispatch(requestDownload(values, downloadType));
    }
  })
);

export default withRedux(DownloadForm);
