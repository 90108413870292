import { connect } from "react-redux";
import Save from "../Save";

import { toggleArticle, getSavedArticleIds } from "../../state/articles/save";

const isActive = (currentId, articleIds) => {
  if (!articleIds) return false;
  return articleIds.filter(id => id === currentId).length > 0;
};

const withRedux = connect(
  (state, props) => ({
    active: isActive(props.entityId, getSavedArticleIds(state))
  }),
  (dispatch, props) => ({
    onClick(e) {
      e.stopPropagation();
      e.preventDefault();
      dispatch(toggleArticle(props.entityId));
    }
  })
);

export default withRedux(Save);
