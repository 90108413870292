import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import BgQuoteLeftIcon from "./bgQuoteLeft.svg";
import BgQuoteRightIcon from "./bgQuoteRight.svg";
import BgQuoteLeftLargeIcon from "./bgQuoteLeftLarge.svg";
import Img from "gatsby-image";

const BlockQuote = ({
  text,
  name,
  jobTitle,
  company,
  extract,
  headShotImage
}) => {
  const attributionStyle = extract ? styles.attribution : styles.attributionTop;

  return (
    <div className={styles.root}>
      <blockquote className={styles.blockquote}>
        {extract && (
          <header className={styles.quoteExtract}>
            <BgQuoteLeftLargeIcon />
            <p className={styles.quoteExtractText}>{extract}</p>
          </header>
        )}

        {text && (
          <div className={styles.mainQuote}>
            <div className={styles.mainQuoteLeftIcon}>
              <BgQuoteLeftIcon />
            </div>
            <p className={styles.mainQuoteText}>{text}</p>
            <div className={styles.mainQuoteRightIcon}>
              <BgQuoteRightIcon />
            </div>
          </div>
        )}

        <footer className={attributionStyle}>
          <cite className={styles.cite}>
            {headShotImage && headShotImage.childImageSharp && (
              <Img
                fixed={headShotImage.childImageSharp.fixed}
                className={styles.headShot}
              />
            )}
            <div className={styles.author}>
              <p className={styles.name}>{name}</p>
              {company && <p className={styles.company}>{company}</p>}
              {jobTitle && <p className={styles.jobTitle}>{jobTitle}</p>}
            </div>
          </cite>
        </footer>
      </blockquote>
    </div>
  );
};

BlockQuote.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string,
  extract: PropTypes.string,
  jobTitle: PropTypes.string,
  company: PropTypes.string,
  headShot: PropTypes.string,
  headShotImage: PropTypes.shape({})
};

export default BlockQuote;
