import React from "react";
// import PropTypes from "prop-types";
import styles from "./index.module.css";

const Terms = () => (
  <div className={styles.root}>
    <div className={styles.inner}>
      <h1 className={styles.heading}>Terms and Conditions</h1>
      <p className={styles.body}>
        The State of European Tech website is owned and operated by Atomico
        (‘Atomico, ‘Us’, ‘We’, ‘Our’). By using or accessing this site, you
        acknowledge that you have read, understood, and agree to be bound by
        these Terms and Conditions (‘T&amp;Cs’). If you do not agree to these
        T&amp;Cs, do not use this site.
      </p>
      <h2 className={styles.subHeading}>Governing Law and Jurisdiction</h2>
      <p className={styles.body}>
        Atomico controls and operates this site from London in the United
        Kingdom. These T&amp;Cs are governed by the laws of England. You agree
        to submit to the non-exclusive jurisdiction of the courts of England for
        all disputes arising out of or related to the content of this site or
        your use of this site. If you choose to access this site from another
        location, you are responsible for compliance with applicable local laws.
      </p>

      <h2 className={styles.subHeading}>Revisions to T&amp;Cs</h2>
      <p className={styles.body}>
        Atomico may revise or amend these T&amp;Cs at any time at our absolute
        discretion by posting revised terms. Any revisions will be effective
        immediately. By continuing to use this site after any revision becomes
        effective, you agree to be bound by the revised T&amp;Cs. You agree
        that, in the event that any portion of these T&amp;Cs is found to be
        unenforceable, the remainder of these T&amp;Cs remain in full force and
        effect.
      </p>

      <h2 className={styles.subHeading}>No Investment Advice</h2>
      <p className={styles.body}>
        The information in this website is for general information only. The
        information is not intended to be investment advice and must not be
        relied upon as such. You should obtain professional investment advice
        tailored to your specific circumstances and needs prior to making any
        investment decisions.
      </p>

      <h2 className={styles.subHeading}>
        Site Content, Ownership, and Use Restrictions
      </h2>
      <p className={styles.body}>
        This site is the copyrighted property of Atomico. This site, and the
        material on the site, is protected by copyright under the laws of
        England and other countries through international treaties. Unless
        otherwise indicated, and except for information directly from or links
        to third-party websites, all rights (including copyright) in all
        content, other material and compilations contained in, or used to,
        create or support this site including text, graphics, logos, button
        icons, video images, audio clips and navigational and other software
        (collectively referred to as the Contents) are owned or controlled, and
        are reserved by Atomico and Our related companies, affiliates, licensors
        and licensees.
      </p>

      <p className={styles.body}>
        The Contents of this site are made available for reuse under a Creative
        Commons license Attribution-NoDerivatives 4.0 International (CC BY-ND
        4.0)
      </p>

      <h2 className={styles.subHeading}>
        Advertising &amp; Links to Third Party Websites
      </h2>
      <p className={styles.body}>
        This site may contain information from or hyperlinks and other pointers
        to internet websites operated by third parties. Such information or
        websites are not under the control of Atomico and We are not responsible
        for the contents of any such information or website or for any goods or
        services that you may acquire from those websites. Atomico provides such
        information and hyperlinks to you as convenience only, and the inclusion
        of any link does not imply any endorsement of the linked website by
        Atomico. You rely on such information or websites entirely at your own
        risk. You are referred to the relevant third party websites for all
        information regarding the third parties products and/or services and for
        terms and conditions under which you may use those websites. The
        responsibility for all issues relating to third party websites,
        including information, goods, services, prices and the delivery of any
        goods or services you purchase on the third party website, lies with the
        third party, subject to their terms and conditions.
      </p>

      <h2 className={styles.subHeading}>Trademarks</h2>
      <p className={styles.body}>
        No trademark (whether registered or otherwise) that is contained on this
        site or otherwise used by Us may be used without our prior, specific,
        written permission or that of the trademark owner.
      </p>

      <h2 className={styles.subHeading}>User Conduct</h2>
      <p className={styles.body}>
        You must not (and agree that you will not) upload, post, transmit or
        otherwise make available through this site any material which:
      </p>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          violates or infringes the rights of others (including their privacy,
          publicity, or intellectual property rights);
        </li>
        <li className={styles.listItem}>
          is unlawful, threatening, abusive, defamatory, invasive of privacy,
          vulgar, obscene, profane or which may harass or cause distress or
          inconvenience to, or incite hatred of, any person;
        </li>
        <li className={styles.listItem}>
          encourages conduct that would constitute a criminal offence, give rise
          to civil liability or otherwise violate any law; restricts or inhibits
          Atomico or any other user from using the site;
        </li>
        <li className={styles.listItem}>
          affects the functionality or operation of the site or the Atomico
          servers or the functionality or operation of any user’s computer
          systems (e.g. by transmitting a computer virus or other harmful
          component, whether or not knowingly); or
        </li>
        <li className={styles.listItem}>
          breaches any standards, content requirements or codes promulgated by
          any relevant authority, including authorities that require Atomico to
          take remedial action under any applicable industry code.
        </li>
      </ul>

      <h2 className={styles.subHeading}>Indemnity</h2>
      <p className={styles.body}>
        You agree to indemnify and will keep Atomico indemnified against any
        claim, demand, injury, damage, loss, expense, cost or liability (whether
        direct or indirect), including reasonable legal fees, made against,
        incurred, or suffered by Us in connection with your use of this site,
        your breach of these T&amp;Cs or your breach of any rights of third
        parties.
      </p>

      <h2 className={styles.subHeading}>Disclaimer</h2>
      <p className={styles.body}>
        To the extent permitted by law, Atomico will not be liable in contract,
        tort (including negligence) or otherwise, for any direct, indirect,
        special, consequential or punitive loss or damages or any loss or
        damages whatsoever, including (but not limited to) loss of use, data, or
        profits, arising out of or in connection with: (1) the use, copying, or
        display of the contents of this site; (2) goods or services supplied by
        Atomico under these T&amp;Cs; or (3) a failure or omission on the part
        of Atomico to comply with Our obligations under these T&amp;Cs.
      </p>
      <p className={styles.body}>
        Atomico provides this site and its contents on an ‘as is’ basis and use
        of this site is at your own risk. Atomico and Our related companies,
        affiliates, licensors or licensees, and their respective directors,
        officers, employees or agents make no representation or warranty as to
        the accuracy, completeness, currency or reliability of the information
        contained on this site (including in relation to any products or
        services). To the extent permitted by law, none of them will be liable
        or responsible in any way (including in negligence) for errors in, or
        omissions from, the information contained on this site. To the extent
        permitted by law, Atomico expressly disclaims all warranties of any
        kind, whether express or implied.
      </p>
      <h2 className={styles.subHeading}>Limitation of liability</h2>
      <p className={styles.body}>
        Our total liability to you (if any) in connection with this site, or
        with these T&amp;Cs or any goods or services supplied under them, will
        be limited at our election to, in the case of information or services
        supplied or offered by us, the re-supply of the information or services.
      </p>
      <h2 className={styles.subHeading}>Viruses</h2>
      <p className={styles.body}>
        This website is not guaranteed to be free from viruses or anything else
        that may interfere with or damage the operations of your computer
        systems. Your use of this website is at your own risk.
      </p>
      <h2 className={styles.subHeading}>Privacy Policy</h2>
      <p className={styles.body}>
        Atomico complies with the requirements of the Data Protection Act 1998
        (UK) and the data protection principles.
      </p>
      <p className={styles.body}>
        You may provide your personal information to us if you choose to
        subscribe to our mailing list. Any personal information you provide to
        us will only be used by us, our agents and service providers, and will
        not be disclosed unless we are obliged or permitted to by law to do so.
      </p>
      <p className={styles.body}>
        We will hold your personal information on our systems for as long as you
        use the service you have requested, and remove it in the event that the
        purpose has been met or when you no longer wish to continue your
        subscription. You may unsubscribe to our mailing list at any time by
        clicking the ‘unsubscribe’ option at the base of our newsletters.
      </p>
      <p className={styles.body}>
        In accordance with the data protection principles, we will store any
        information you provide to us securely and only use it for the purposes
        for which it has been provided. We will never sell your personal
        information.
      </p>
      <p className={styles.body}>
        Atomico complies with EU General Data Protection Regulation (“GDPR”).
      </p>
      <p className={styles.body}>
        If you have any queries about privacy matters, please contact Atomico
      </p>
    </div>
  </div>
);

export default Terms;
