import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Seo from "../Seo";
import styles from "./index.module.css";
import ScrollArrow from "./scroll.svg";

import SVG from "../Svg";

const Chapter = ({
  entityId,
  titleNumber,
  title,
  subTitle,
  text,
  path,
  articles
}) => {
  return (
    <div
      className={styles.root}
      id={`chapter-${entityId}`}
      data-observe-position
      data-pathname={path}
    >
      <Seo title={title} description={subTitle || null} path={path} />
      <h1 data-chapter={titleNumber} className={styles.title}>
        <span className={styles.titleText}>{title}</span>
      </h1>
      <div className={styles.chapterContent}>
        <div className={styles.chapterContentCol}>
          <p className={styles.firstParagraph}>{subTitle}</p>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
        <div className={styles.chapterContentCol}>
          <h2 className={styles.articlesSubtitle}>Articles</h2>
          <hr className={styles.articlesHr} />
          <ol>
            {articles.map(article => {
              return (
                <li key={article.id} className={styles.articlesItem}>
                  <Link className={styles.articlesItemLink} to={article.path}>
                    <span className={styles.articlesNum}>
                      {article.titleNumber}
                    </span>
                    <span>{article.title}</span>
                  </Link>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
      <div className={styles.scrollArrow}>
        <SVG svg={ScrollArrow} className={styles.scrollArrowSvg} />
      </div>
    </div>
  );
};

Chapter.propTypes = {
  entityId: PropTypes.number,
  titleNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      path: PropTypes.string,
      title: PropTypes.string,
      titleNumber: PropTypes.string
    })
  )
};

export default Chapter;
