import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import Svg from "../Svg";
import closeIcon from "./closeIcon.svg";

const CloseButton = ({ onClick, inverted }) => (
  <button
    className={inverted ? styles.rootInverted : styles.root}
    onClick={onClick}
  >
    <span className={styles.buttonInner}>
      <Svg className={styles.closeIcon} svg={closeIcon} cleanup={[`fill`]} />
    </span>
  </button>
);

CloseButton.propTypes = {
  onClick: PropTypes.func,
  inverted: PropTypes.bool
};

export default CloseButton;
