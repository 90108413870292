import { connect } from "react-redux";
import CookieMessage from "../CookieMessage";

import { setCookieNotice, getCookieNotice } from "../../state/cookieNotice";

const withRedux = connect(
  state => ({
    acceptedCookieNotice: getCookieNotice(state)
  }),
  dispatch => ({
    dismissCookieNotice() {
      dispatch(setCookieNotice());
    }
  })
);

export default withRedux(CookieMessage);
