import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import Insight from "../Insight";

const BlockInsight = ({ title, skipTitle, items }) => (
  <div className={styles.root}>
    <div className={styles.contentCols}>
      <div className={styles.contentCol}>
        {title && !skipTitle && <h1 className={styles.title}>{title}</h1>}
      </div>
      <div className={styles.contentCol}>
        {items.length > 0 && (
          <div className={styles.insights}>
            {items.map((item, i) => (
              <Insight key={i} {...item} />
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
);

BlockInsight.defaultProps = {
  items: []
};

BlockInsight.propTypes = {
  title: PropTypes.string,
  skipTitle: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape(Insight.propTypes))
};

export default BlockInsight;
