import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

const KeyFindingsList = ({ data }) => (
  <div className={styles.root}>
    <ul className={styles.list}>
      {data.map((item, index) => {
        return (
          <li key={index} className={styles.item}>
            {item.title && <p className={styles.title}>{item.title}</p>}
            {item.text && <p className={styles.text}>{item.text}</p>}
          </li>
        );
      })}
    </ul>
  </div>
);

KeyFindingsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  )
};

export default KeyFindingsList;
