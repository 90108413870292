import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

const BlockSpacer = ({ dark }) => {
  const keyline = dark ? styles.keylineDark : styles.keyline;
  return (
    <div className={styles.root}>
      <div className={keyline} />
    </div>
  );
};

BlockSpacer.propTypes = {
  dark: PropTypes.bool
};

export default BlockSpacer;
