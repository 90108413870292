import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styles from "./index.module.css";

const ArticleFooter = ({ next }) => {
  if (!next) return null;
  return (
    <div className={styles.root}>
      <nav className={styles.nav} data-title-number={next.titleNumber}>
        <Link to={next.path} className={styles.navItem}>
          <span className={styles.nextText}>
            Next {next.type === `chapter` ? `Chapter` : `Article`}
          </span>
          <hr className={styles.hr} />
          <span className={styles.nextTitle}>{next.title}</span>
          <div className={styles.downArrow} />
        </Link>
      </nav>
    </div>
  );
};

ArticleFooter.propTypes = {
  next: PropTypes.shape({
    path: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    titleNumber: PropTypes.titleNumber
  })
};

export default ArticleFooter;
