import storage from "../../utils/storage";

// Actions
const typePrefix = `cookieNotice/`;
const CLEAR = `${typePrefix}CLEAR`;
const SET = `${typePrefix}SET`;

// Selectors

export const getCookieNotice = state => state.cookieNotice.cookieNotice;

// Action creators

/**
 *
 * @return {function} thunk action creator
 */
export const setCookieNotice = () => dispatch => {
  dispatch({ type: SET });
  const serialised = JSON.stringify({ cookieNotice: true });
  storage.setItem(typePrefix, serialised);
};

export const clearCookieNotice = () => dispatch => {
  dispatch({ type: CLEAR });
  const serialised = JSON.stringify(emptyState);
  storage.setItem(typePrefix, serialised);
};

const emptyState = {
  cookieNotice: false
};

/**
 * Rehydrate the state from storage.
 * @return {object} Previously saved auth state or an empty object
 */
export const rehydrate = () => {
  const cookieNotice = storage.getItem(typePrefix);
  const hydrated =
    typeof cookieNotice === `string` ? JSON.parse(cookieNotice) : emptyState;
  return hydrated;
};

// Reducer
export function cookieNoticeReducer(state = rehydrate(), action = {}) {
  switch (action.type) {
    case SET:
      return {
        cookieNotice: true
      };
    case CLEAR:
      return { ...emptyState };
    default:
      return state;
  }
}
