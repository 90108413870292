import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

const BlockFreeText = ({ title, skipTitle, subTitle, text, addKeyLine }) => {
  const rootStyle = addKeyLine ? styles.rootWithKeyline : styles.root;
  return (
    <div className={rootStyle}>
      {title && !skipTitle && <h1 className={styles.title}>{title}</h1>}
      <div className={styles.contentCols}>
        <div className={styles.contentCol}>
          {subTitle && (
            <div
              className={styles.subTitle}
              dangerouslySetInnerHTML={{ __html: subTitle }}
            />
          )}
        </div>
        <div className={styles.contentCol}>
          {text && (
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

BlockFreeText.propTypes = {
  title: PropTypes.string,
  skipTitle: PropTypes.bool,
  subTitle: PropTypes.string,
  text: PropTypes.string,
  addKeyLine: PropTypes.bool
};

export default BlockFreeText;
