import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Chapter from "../components/Chapter";
import ArticleFooter from "../components/ArticleFooter";
import ReportLayout from "../components/ReportLayout";
import InfiniteArticles from "../components/InfiniteArticles";

class ChapterTemplate extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    const chapter = data.soetChapter;
    const nexts = data.allSoetArticle.edges.concat(data.allSoetChapter.edges);
    const nextMap = nexts.reduce((map, item) => {
      map[item.node.entityId] = { next: item.node.next, path: item.node.path };
      return map;
    }, {});
    return (
      <ReportLayout>
        <InfiniteArticles
          FooterComponent={ArticleFooter}
          article={chapter}
          nextMap={nextMap}
        >
          <Chapter
            entityId={chapter.entityId}
            path={chapter.path}
            next={chapter.next}
            subTitle={chapter.subTitle}
            text={chapter.text}
            title={chapter.title}
            titleNumber={chapter.titleNumber}
            articles={chapter.soetArticle}
          />
        </InfiniteArticles>
      </ReportLayout>
    );
  }
}

export default ChapterTemplate;

const articlePreviewPropTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.string,
  type: PropTypes.string
};

ChapterTemplate.propTypes = {
  data: PropTypes.shape({
    soetChapter: PropTypes.shape({
      articlePreviews: PropTypes.arrayOf(
        PropTypes.shape({
          ...articlePreviewPropTypes
        })
      ),
      subTitle: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string
    })
  })
};

/**
 * We want to get:
 *
 * - current chapter
 * - article previews for this chapter
 */
// eslint-disable-next-line no-undef
export const chapterQuery = graphql`
  query ChapterQuery($entityId: Int!) {
    soetChapter(entityId: { eq: $entityId }) {
      id
      entityId
      next {
        entityId
        path
        pathStandalone
        title
        titleNumber
        type
      }
      path
      subTitle
      text
      title
      titleNumber
      soetArticle {
        id
        entityId
        path
        title
        titleNumber
      }
    }
    allSoetArticle {
      edges {
        node {
          entityId
          path
          next {
            entityId
            path
            pathStandalone
            title
            titleNumber
            type
          }
        }
      }
    }
    allSoetChapter {
      edges {
        node {
          entityId
          path
          next {
            entityId
            path
            pathStandalone
            title
            titleNumber
            type
          }
        }
      }
    }
  }
`;
