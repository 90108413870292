import React from "react";
import PropTypes from "prop-types";

import mapSvg from "./map.svg";
import styles from "./index.module.css";
import Svg from "../Svg";

const CountryHighlightsMap = ({ countryCode }) => (
  <div className={styles.root}>
    <div className={countryCode ? styles[countryCode] : styles.inner}>
      <Svg svg={mapSvg} alt="Map" />
    </div>
  </div>
);

CountryHighlightsMap.propTypes = {
  countryCode: PropTypes.string
};

export default CountryHighlightsMap;
