import React from "react";
// import PropTypes from "prop-types";

import TabChrome from "../TabChrome";
import AllArticlesPanel from "../AllArticlesPanel";
import SavedArticlesTabContainer from "../SavedArticlesTab/container.js";
import SavedArticlesPanelContainer from "../SavedArticlesPanel/container.js";
import styles from "./index.module.css";

const AllArticlesTab = () => <React.Fragment>All</React.Fragment>;

const ContentsPanel = ({ chapters, allArticleIds, location }) => {
  return (
    <TabChrome
      tabs={[
        <AllArticlesTab key="all" tabClassName={styles.tab} />,
        <SavedArticlesTabContainer
          key="saved"
          savedCount={10}
          tabClassName={styles.tab}
        />
      ]}
      config={{
        selectedTabClassName: styles.selectedTabClassName
      }}
    >
      <AllArticlesPanel
        chapters={chapters}
        windowPathname={location.pathname}
      />
      <SavedArticlesPanelContainer
        chapters={chapters}
        allArticleIds={allArticleIds}
      />
    </TabChrome>
  );
};

ContentsPanel.propTypes = AllArticlesPanel.propTypes;

export default ContentsPanel;
