import { connect } from "react-redux";
import Splash from "../Splash";

import { showModal, modals } from "../../state/modal";

const withRedux = connect(
  (state, props) => ({
    reportPath: props.reportPath
  }),
  dispatch => ({
    onDownloadClick() {
      const modal = {
        modalType: modals.DOWNLOAD_REPORT,
        modalProps: {}
      };
      dispatch(showModal(modal));
    }
  })
);

export default withRedux(Splash);
