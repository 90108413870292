import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";

import { rootReducer } from "./state";

/**
 * This array of strings as regex defines which actions are blacklisted from
 * both logging on Redux DevTools.
 */
const actionsBlacklist = [];

const middleware = [thunk];

const composeEnhancers = composeWithDevTools({
  maxAge: 200,
  actionsBlacklist
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

if (module.hot) {
  module.hot.accept(`./state`, () => {
    const nextRootReducer = require(`./state/index`).rootReducer;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
