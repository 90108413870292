import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Index, connectStateResults } from "react-instantsearch-dom";
import get from "lodash/get";

import SearchInput from "../../SearchInput";
import ResultsWrapper from "../ResultsWrapper";
import ArticleHit from "../ArticleHit";
import DataHit from "../DataHit";

import styles from "./index.module.css";

const ResultCount = connectStateResults(({ searchState, searchResults }) => {
  const hasQuery = !!searchState && !!searchState.query;
  if (!hasQuery) return `-`;
  return get(searchResults, `nbHits`, 0);
});

const ArticleResultsTab = () => (
  <React.Fragment>
    Articles (<ResultCount />)
  </React.Fragment>
);

const DataResultsTab = () => (
  <React.Fragment>
    Data (
    <Index indexName={process.env.GATSBY_ALGOLIA_CHART_INDEX}>
      <ResultCount />
    </Index>
    )
  </React.Fragment>
);

const DataResultsPanel = () => {
  return (
    <Index indexName={process.env.GATSBY_ALGOLIA_CHART_INDEX}>
      <ResultsWrapper hitComponent={DataHit} />
    </Index>
  );
};

const ArticleResultsPanel = () => <ResultsWrapper hitComponent={ArticleHit} />;

const SearchPanel = () => {
  return (
    <Tabs
      {...{
        className: styles.root,
        selectedTabClassName: styles.tabSelected,
        disabledTabClassName: styles.tabDisabled,
        selectedTabPanelClassName: styles.tabPanelSelected,
        defaultIndex: 0
      }}
    >
      <div className={styles.searchInput}>
        <SearchInput />
      </div>
      <TabList className={styles.tabList}>
        <Tab className={styles.tab}>
          <ArticleResultsTab tabClassName={styles.tab} />
        </Tab>
        <Tab className={styles.tab}>
          <DataResultsTab tabClassName={styles.tab} />
        </Tab>
      </TabList>
      <TabPanel className={styles.tabPanel}>
        <ArticleResultsPanel />
      </TabPanel>
      <TabPanel className={styles.tabPanel}>
        <DataResultsPanel />
      </TabPanel>
    </Tabs>
  );
};

export default SearchPanel;
