import React from "react";
import PropTypes from "prop-types";

import PrimaryButton from "../PrimaryButton";
import ScrollArrow from "./scroll.svg";
import styles from "./index.module.css";
import europeMap from "./europe_map_simple.svg";

import SVG from "../Svg";

const Splash = ({ reportPath, onDownloadClick }) => (
  <div className={styles.root}>
    <div className={styles.inner}>
      <div className={styles.lockup}>
        <div className={styles.header}>
          <h1 className={styles.title}>
            The State of European Tech <span className={styles.year}>2018</span>
          </h1>
          <hr className={styles.hr} />
        </div>
        <div className={styles.text}>
          <p className={styles.textParagraphBold}>
            Welcome to the most comprehensive
            <br />
            data-driven analysis of European technology
          </p>
          <div className={styles.textParagraph}>
            <span className={styles.scrollArrow}>
              <SVG svg={ScrollArrow} className={styles.scrollArrowSvg} />
            </span>
            Scroll for the key findings or click to explore the full report
          </div>
        </div>

        <nav className={styles.nav}>
          <div className={styles.navItem}>
            <PrimaryButton to={reportPath} type="link" arrow={true}>
              Explore the report
            </PrimaryButton>
          </div>
          <div className={styles.navItem}>
            <PrimaryButton inverted={true} onClick={onDownloadClick}>
              Download the pdf
            </PrimaryButton>
          </div>
        </nav>
      </div>
    </div>
    <div className={styles.map}>
      <SVG svg={europeMap} />
    </div>
  </div>
);

Splash.propTypes = {
  reportPath: PropTypes.string,
  onDownloadClick: PropTypes.func
};

export default Splash;
