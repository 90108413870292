/**
 * This file responsible for the download chart flow. The first time a user
 * requests to download a chart, they will be asked for their email address
 * and to agree to the terms and conditions.
 *
 * The flow is roughly like this:
 *
 * - Click 'download chart button'
 * - Do we have state.user?
 *    - No, trigger request user details modal flow
 *    - Yes, go to next step
 * - Trigger file download
 */
import { getUser } from "../user";
import { getChart, setChart } from "../chart"; // eslint-disable-line no-unused-vars
import { showModal, modals, mailchimpRequest } from "../../state/modal";
import analytics from "../../utils/analytics";

/**
 * Request chart download - check for user details. If available then skip
 * straight to triggering the chart download. If _not_ available, show the
 * standard user request details.
 **/
// eslint-disable-next-line no-unused-vars
export const requestChartDownload = ({
  chartId,
  groupId,
  chartRef,
  titleNumber,
  disabledDataDownload
}) => (dispatch, getState) => {
  const state = getState();
  const user = getUser(state);

  // set chart
  dispatch(
    setChart({ chartId, groupId, chartRef, titleNumber, disabledDataDownload })
  );

  // get user details
  const hasUser =
    typeof user.email === `string` &&
    user.email.length > 1 &&
    user.termsAgreed === true;

  // Default modal to show next.
  let modal = { modalType: modals.DOWNLOAD_CHART };
  const modalProps = {
    disabledDataDownload: disabledDataDownload
  };

  if (hasUser && disabledDataDownload) {
    // There's only one choice for download so go straight to image download.
    dispatch(prepChartImageDownload());
    return false;
  } else if (hasUser) {
    // if user details, goto 'agreed' modal
    modal = { modalType: modals.DOWNLOAD_CHART_AGREED };
  }

  modal.modalProps = modalProps;

  dispatch(showModal(modal));
};

// eslint-disable-next-line no-unused-vars
export const prepChartImageDownload = () => (dispatch, getState) => {
  const state = getState();
  const user = getUser(state);

  mailchimpRequest(user.email, user.newsletter).then(() => {
    dispatch(
      showModal({
        modalType: modals.DOWNLOAD_SUCCESS,
        modalProps: {
          text: `Your download will start in a few moments. Check your browser's download folder.`
        }
      })
    );
    dispatch(downloadChartImage());
  });
};

// eslint-disable-next-line no-unused-vars
export const prepChartDataDownload = () => (dispatch, getState) => {
  const state = getState();
  const user = getUser(state);

  mailchimpRequest(user.email, user.newsletter).then(() => {
    dispatch(
      showModal({
        modalType: modals.DOWNLOAD_SUCCESS,
        modalProps: {
          text: `Your download will start in a few moments. Check your browser's download folder.`
        }
      })
    );
    dispatch(downloadChartData());
  });
};

/**
 * Trigger the download of the current chart
 */
export const downloadChartImage = () => (dispatch, getState) => {
  const state = getState();
  const chartDetails = getChart(state);
  const filePrefix = `chart/chart-`;
  const titleNumberFragment = chartDetails.titleNumber.split(`.`).join(`-`);
  const fileSuffix = `${titleNumberFragment}-${chartDetails.chartId}-${
    chartDetails.groupId
  }`;
  const filename = `${filePrefix}${fileSuffix}.png`;
  const chart = `${window.location.origin}/${filename}`;
  analytics(`send`, `event`, `download`, `downloadChart`, chart);
  // Create a temporary element and click it to download the image.
  // These images do not have the "Content-Disposition: attachment" header.
  // So we do this to try and force the image to download, instead of display
  // in the browser
  const element = document.createElement(`a`);
  element.setAttribute(`href`, chart);
  element.setAttribute(`download`, filename);
  element.style.display = `none`;

  // if download attribute not supported, just open in a new browser window
  if (!element.hasAttribute(`download`)) element.target = `_blank`;

  // Attach, click, remove:
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadChartData = () => (dispatch, getState) => {
  const state = getState();
  const chartDetails = getChart(state);

  const csvHeader = `data:text/csv;charset=utf-8,`;
  const csvData = chartDetails.chartRef.getCSV();
  const encodedCSV = encodeURI(csvHeader + csvData);

  const filePrefix = `chart/chart-`;
  const fileSuffix = `${chartDetails.chartId}-${chartDetails.groupId}`;
  const filename = `${filePrefix}${fileSuffix}.csv`;
  analytics(`send`, `event`, `download`, `downloadChartData`, filename);
  // Create a temporary element and click it to download the image.
  // These images do not have the "Content-Disposition: attachment" header.
  // So we do this to try and force the image to download, instead of display
  // in the browser
  const element = document.createElement(`a`);
  element.setAttribute(`href`, encodedCSV);
  element.setAttribute(`download`, filename);
  element.style.display = `none`;

  // if download attribute not supported, just open in a new browser window
  if (!element.hasAttribute(`download`)) element.target = `_blank`;

  // Attach, click, remove:
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
