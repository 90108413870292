import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

const SEOWithoutStaticQuery = ({
  title,
  description,
  image,
  path,
  article,
  url,
  siteMetadata
}) => {
  const seo = {
    title: title || siteMetadata.defaultTitle,
    description: description || siteMetadata.defaultDescription,
    image: `${url || siteMetadata.siteUrl}${image ||
      siteMetadata.defaultImage}`,
    url: `${url || siteMetadata.siteUrl}${path || `/`}`
  };

  return (
    <>
      <Helmet
        title={seo.title}
        titleTemplate={siteMetadata.titleTemplate}
        htmlAttributes={{ lang: `en` }}
      >
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta name="twitter:card" content="summary_large_image" />
        {siteMetadata.twitterUsername && (
          <meta name="twitter:creator" content={siteMetadata.twitterUsername} />
        )}
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
      </Helmet>
    </>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
            defaultImage: image
            twitterUsername
          }
        }
      }
    `}
    render={data => (
      <SEOWithoutStaticQuery siteMetadata={data.site.siteMetadata} {...props} />
    )}
  />
);

SEOWithoutStaticQuery.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  path: PropTypes.string,
  article: PropTypes.bool,
  url: PropTypes.string,
  siteMetadata: PropTypes.shape({
    defaultTitle: PropTypes.string,
    titleTemplate: PropTypes.string,
    defaultDescription: PropTypes.string,
    siteUrl: PropTypes.string,
    defaultImage: PropTypes.string,
    twitterUsername: PropTypes.string
  })
};

SEOWithoutStaticQuery.defaultProps = {
  title: null,
  description: null,
  image: null,
  path: null,
  article: false,
  url: null
};
