import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

const KeyFindingsText = ({ data }) => (
  <div className={styles.root}>
    {data.map((item, index) => {
      return (
        <div key={index} className={styles[item.type]}>
          <p>{item.content}</p>
        </div>
      );
    })}
  </div>
);

KeyFindingsText.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  )
};

export default KeyFindingsText;
