import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Article from "../components/Article";
import { ArticleFieldsFragment, ChartBlockFragment } from "./article.js"; // eslint-disable-line

const StandaloneArticle = ({ data }) => {
  const article = data.soetArticle;
  return (
    <React.Fragment>
      <Article
        path={article.path}
        entityId={article.entityId}
        articleType={article.type}
        titleNumber={article.titleNumber}
        title={article.title}
        sources={article.sources}
        blocks={article.blocks}
      />
    </React.Fragment>
  );
};

StandaloneArticle.propTypes = {
  data: PropTypes.shape({
    soetArticle: PropTypes.shape({
      block: PropTypes.shape()
    })
  }),
  pageContext: PropTypes.shape({
    entityId: PropTypes.number,
    articlePath: PropTypes.string
  })
};

export default StandaloneArticle;

/**
 * Get full article details
 */
// eslint-disable-next-line no-undef
export const standaloneArticleQuery = graphql`
  query StandaloneArticleQuery($entityId: Int!) {
    soetArticle(entityId: { eq: $entityId }) {
      id
      ...ArticleFieldsFragment
    }
  }
`;
