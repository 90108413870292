import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import { graphql, StaticQuery, Link } from "gatsby";
import Svg from "../Svg";
import NavContext from "../NavContext";

import closeIcon from "./closeIcon.svg";

const isReportRoute = path => /^(\/chapter\/|\/article\/)/.test(path);
const isSearchRoute = path =>
  path.startsWith(`/search-data`) || path.startsWith(`/search-articles`);

const MenuBarWithoutQuery = ({
  reportNavOpen,
  toggleReportNav,
  closeReportNav,
  data,
  activePathname,
  pathname
}) => {
  const closeIconClass = reportNavOpen ? styles.closeVisible : styles.close;
  const titles = data.allSoetArticle.edges.concat(data.allSoetChapter.edges);
  const titleMap = titles.reduce((map, item) => {
    map[item.node.path] = {
      title: item.node.title,
      titleNumber: item.node.titleNumber,
      nextPath: item.node.next ? item.node.next.path : false
    };
    return map;
  }, {});

  let menuName = `Contents`;
  let titleNumber = ``;
  let nextPath;
  if (isReportRoute(activePathname) && titleMap[activePathname]) {
    titleNumber = titleMap[activePathname].titleNumber;
    nextPath = titleMap[activePathname].nextPath;
  }
  if (isSearchRoute(pathname)) {
    titleNumber = `Search`;
    nextPath = null;
    menuName = `Menu`;
  }

  return (
    <div className={styles.root}>
      <a className={styles.menu} onClick={toggleReportNav} title="Menu">
        {menuName}
      </a>
      <h1 className={styles.title}>
        <span className={styles.titleText}>{titleNumber}</span>
      </h1>
      {nextPath && (
        <Link to={nextPath} className={styles.next} title="Next article">
          Next
        </Link>
      )}
      <div className={closeIconClass} onClick={closeReportNav}>
        <Svg svg={closeIcon} alt="Close menu" className={styles.closeIcon} />
      </div>
    </div>
  );
};

MenuBarWithoutQuery.propTypes = {
  reportNavOpen: PropTypes.bool,
  toggleReportNav: PropTypes.func,
  closeReportNav: PropTypes.func,
  currentPage: PropTypes.string,
  activePathname: PropTypes.string,
  data: PropTypes.shape({}),
  pathname: PropTypes.string
};

const MenuBar = props => (
  <StaticQuery
    query={graphql`
      {
        allSoetChapter {
          edges {
            node {
              entityId
              path
              title
              titleNumber
              next {
                path
              }
            }
          }
        }
        allSoetArticle {
          edges {
            node {
              entityId
              path
              title
              titleNumber
              next {
                path
              }
            }
          }
        }
      }
    `}
    render={data => <MenuBarWithoutQuery data={data} {...props} />}
  />
);

const provideContext = Component => props => {
  return (
    <NavContext.Consumer>
      {({ activePathname, reportNavOpen, toggleReportNav, closeReportNav }) => (
        <Component
          activePathname={activePathname}
          reportNavOpen={reportNavOpen}
          toggleReportNav={toggleReportNav}
          closeReportNav={closeReportNav}
          {...props}
        />
      )}
    </NavContext.Consumer>
  );
};

export default provideContext(MenuBar);
