import React from "react";
import PropTypes from "prop-types";

import NavChapter from "../NavChapter";
import noSavedImage from "./no_saved.png";

import styles from "./index.module.css";

// Take an array of chapters and articleids
// Remove articles who's id is not contained in matchArticleIds
// Remove chapters that have no articles
const filterArticlesInChapters = (chapters, matchArticleIds) => {
  const filtered = chapters
    .map(chapter => {
      // find chapter articles that exist in matchArticleIds
      const articles = chapter.soetArticle.filter(art =>
        matchArticleIds.includes(art.entityId)
      );

      // no matching articles in this chapter
      if (articles.length === 0) return null;

      // amend chapter to only include matched articles
      return { ...chapter, soetArticle: articles };
    })
    .filter(chapter => chapter !== null);

  return filtered;
};

const NoSavedResults = () => (
  <div className={styles.noSaved}>
    <p className={styles.noSavedText}>
      Click the star icon to save your favourite articles
    </p>
    <img className={styles.noSavedImage} src={noSavedImage} />
  </div>
);

class SavedArticlesPanel extends React.Component {
  componentDidMount() {
    this.props.setValidIds(this.props.allArticleIds);
  }
  render() {
    const { chapters, savedArticleIds } = this.props;
    const savedChapters = filterArticlesInChapters(chapters, savedArticleIds);
    return (
      <div className={styles.root}>
        {savedChapters.length > 0 ? (
          savedChapters.map(chapter => (
            <NavChapter
              chapter={chapter}
              forceShowArticles={true}
              key={chapter.id}
            />
          ))
        ) : (
          <NoSavedResults />
        )}
      </div>
    );
  }
}

SavedArticlesPanel.propTypes = {
  chapters: PropTypes.arrayOf(
    PropTypes.shape({
      // other fields...
      soetArticle: PropTypes.arrayOf(
        PropTypes.shape({
          // other fields...
          entityId: PropTypes.number
        })
      )
    })
  ),
  setValidIds: PropTypes.func,
  savedArticleIds: PropTypes.arrayOf(PropTypes.number),
  allArticleIds: PropTypes.arrayOf(PropTypes.number)
};

export default SavedArticlesPanel;
