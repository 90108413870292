import { Highlight, Snippet } from "react-instantsearch-dom";
import { Link } from "gatsby";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";

import styles from "./index.module.css";

const HIT_MATCH = /^(full|partial)$/;

const matchHitSnippet = (hit, attribute) => {
  const res = get(hit, `_snippetResult.${attribute}.matchLevel`, ``).match(
    HIT_MATCH
  );
  return res;
};

const MaybeSnippet = ({ hit, attribute }) => {
  if (!matchHitSnippet(hit, attribute)) return null;
  return (
    <p className={styles.snippet}>
      <Snippet attribute={attribute} hit={hit} />
    </p>
  );
};

MaybeSnippet.propTypes = {
  hit: PropTypes.shape({}),
  attribute: PropTypes.string
};

const MaybeHighlightGroup = ({ hit, attribute, className }) => {
  if (hit.group.name === `Default`) return null;
  return (
    <span className={className}>
      Dataset:
      <Highlight
        attribute="group.name"
        hit={hit}
        className={styles.groupNameHighlight}
      />
    </span>
  );
};

MaybeHighlightGroup.propTypes = {
  hit: PropTypes.shape({}),
  attribute: PropTypes.string,
  className: PropTypes.string
};

const Hit = ({ hit }) => {
  const anchorId = `#chart-${hit.chartId}-${hit.group.id}`;
  return (
    <Link to={`/search-data${anchorId}`} className={styles.root}>
      <p className={styles.titleNumber}>{hit.titleNumber}</p>
      <Highlight attribute="title" hit={hit} className={styles.title} />
      <MaybeHighlightGroup
        attribute="group.name"
        hit={hit}
        className={styles.groupName}
      />
      <MaybeSnippet attribute="insight" hit={hit} />
      <MaybeSnippet attribute="insight.title" hit={hit} />
    </Link>
  );
};

Hit.propTypes = {
  hit: PropTypes.shape({})
};

export default Hit;
