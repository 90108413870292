import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

const KeyFindingsStats = ({ data }) => (
  <div className={styles.root}>
    {data.map((item, index) => {
      return (
        <div className={styles.stat} key={index}>
          <p className={styles.subtitle}>{item.subtitle}</p>
          <div className={styles.inner}>
            <div className={styles.itemCol}>
              <p className={styles.itemValue}>
                {item.valuePrefix && (
                  <span className={styles.valuePrefix}>{item.valuePrefix}</span>
                )}
                <span className={styles.value}>{item.value}</span>
                {item.valueSuffix && (
                  <span className={styles.valueSuffix}>{item.valueSuffix}</span>
                )}
              </p>
            </div>
            <div className={styles.itemCol}>
              <p className={item.source ? styles.textWithSource : styles.text}>
                {item.text}
              </p>
              {item.source && (
                <p className={styles.source}>Source: {item.source}</p>
              )}
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

KeyFindingsStats.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      valuePrefix: PropTypes.string,
      value: PropTypes.string.isRequired,
      valueSuffix: PropTypes.string,
      subtitle: PropTypes.string.isRequired,
      source: PropTypes.string,
      text: PropTypes.string.isRequired
    })
  )
};

export default KeyFindingsStats;
