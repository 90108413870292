import React from "react";
import PropTypes from "prop-types";
import Svg from "../Svg";
import styles from "./index.module.css";
import twitterIcon from "./twitterIcon.svg";
import linkedInIcon from "./linkedInIcon.svg";
import analytics from "../../utils/analytics";

const trackShare = (type, url) => {
  analytics(`send`, `event`, `click`, `clickSocialShare`, `${type} ${url}`);
};

export const ShareLinkedIn = ({
  url,
  sourceUrl,
  summary,
  title,
  large,
  inverted
}) => {
  const queryParams = {
    url,
    sourceUrl,
    title: title,
    summary: summary || ``,
    source: `Atomico`
  };
  const queryString = Object.keys(queryParams)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryParams[k])}`)
    .join(`&`);

  return (
    <Share
      icon={linkedInIcon}
      url={`https://www.linkedin.com/shareArticle?${queryString}`}
      large={large}
      inverted={inverted}
      onClick={() => {
        trackShare(`linkedIn`, sourceUrl);
      }}
    />
  );
};

ShareLinkedIn.propTypes = {
  image: PropTypes.string,
  url: PropTypes.string,
  sourceUrl: PropTypes.string,
  summary: PropTypes.string,
  title: PropTypes.string,
  large: PropTypes.bool,
  inverted: PropTypes.bool
};

export const ShareTwitter = ({ text, url, large, inverted }) => {
  const queryParams = {
    text,
    url,
    hashtags: `europeantech`
  };
  const queryString = Object.keys(queryParams)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryParams[k])}`)
    .join(`&`);
  return (
    <Share
      icon={twitterIcon}
      url={`https://twitter.com/intent/tweet?${queryString}`}
      large={large}
      inverted={inverted}
      onClick={() => {
        trackShare(`twitter`, url);
      }}
    />
  );
};

ShareTwitter.propTypes = {
  hashtags: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  large: PropTypes.bool,
  inverted: PropTypes.bool
};

const Share = ({ url, icon, large, inverted }) => {
  let style = large ? styles.rootLarge : styles.root;
  style = inverted ? styles.rootInverted : style;
  return (
    <a className={style} href={url} target="_blank" rel="noopener noreferrer">
      <Svg className={styles.icon} svg={icon} />
    </a>
  );
};

Share.defaultProps = {
  type: `twitter`
};

Share.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.func, // an svg
  large: PropTypes.bool,
  inverted: PropTypes.bool
};

export default Share;
