import React from "react";
import PropTypes from "prop-types";
import NavActionsContainer from "../NavActions/container";
import NavChapter from "../NavChapter";
import NavContext from "../../NavContext";
// import get from "lodash/get";

import styles from "./index.module.css";

class AllArticlesPanel extends React.Component {
  constructor(props) {
    super(props);
    this.panelRef = React.createRef();
    this.updateRefMap = this.updateRefMap.bind(this);
    this.scrollToActiveChapter = this.scrollToActiveChapter.bind(this);

    this.state = {
      refMap: {}
    };
  }

  updateRefMap(entityId, chapterRef) {
    this.setState(state => ({
      refMap: {
        ...state.refMap,
        [entityId]: chapterRef
      }
    }));
  }

  scrollToActiveChapter(entityId) {
    const { refMap } = this.state;
    const entityRef = refMap && refMap[entityId];
    if (!entityRef) return;

    // setTimeout(() => {
    // const top = entityRef.current.offsetTop;
    // console.log(entityRef.current, entityRef.current.getBoundingClientRect());
    // console.log(this.panelRef.current.getBoundingClientRect());
    // this.panelRef.current.scroll({ top: top - 42, behavior: `smooth` });
    // }, 100);
  }

  render() {
    const {
      chapters,
      windowPathname,
      activePathname,
      closeReportNav
    } = this.props;
    return (
      <div className={styles.root} ref={this.panelRef}>
        {chapters.map(chapter => (
          <NavChapter
            chapter={chapter}
            showIcon={true}
            to={chapter.path}
            isActiveChapter={windowPathname.includes(chapter.path)}
            key={chapter.entityId}
            updateRefMap={this.updateRefMap}
            scrollToActiveChapter={this.scrollToActiveChapter}
            activePathname={activePathname}
            closeReportNav={closeReportNav}
          />
        ))}
        <NavActionsContainer />
      </div>
    );
  }
}

AllArticlesPanel.propTypes = {
  chapters: PropTypes.array,
  windowPathname: PropTypes.string,
  scrollToActiveChapter: PropTypes.func,
  updateRefMap: PropTypes.func,
  activePathname: PropTypes.string,
  closeReportNav: PropTypes.func
};

const provideContext = Component => props => {
  return (
    <NavContext.Consumer>
      {({ activePathname, closeReportNav }) => (
        <Component
          activePathname={activePathname}
          closeReportNav={closeReportNav}
          {...props}
        />
      )}
    </NavContext.Consumer>
  );
};

export default provideContext(AllArticlesPanel);
