import { setUser } from "../user";
import {
  prepChartImageDownload,
  prepChartDataDownload
} from "../download/chart";
import { prepReportDownload } from "../download/report";

// minimal fetch implementation
import unfetch from "unfetch/dist/unfetch.umd.js";

// Global request config
const unReq = ({ data, method, url }) => {
  return unfetch(url, {
    method,
    headers: {
      Accept: `application/json, text/plain, */*`,
      "Content-Type": `application/json;charset=UTF-8`,
      "x-api-key": process.env.GATSBY_MAILCHIMP_API_KEY
    },
    body: JSON.stringify(data)
  });
};

/**
 * This list is used to map modals to different components and / or actions.
 */
export const modals = {
  DOWNLOAD_REPORT: Symbol(`DOWNLOAD_REPORT`),
  DOWNLOAD_CHART: Symbol(`DOWNLOAD_CHART`),
  DOWNLOAD_CHART_AGREED: Symbol(`DOWNLOAD_CHART_AGREED`),
  DOWNLOAD_SUCCESS: Symbol(`DOWNLOAD_SUCCESS`),
  DOWNLOAD_ERROR: Symbol(`DOWNLOAD_ERROR`)
};

export const chartDownloadTypes = {
  CHART_DOWNLOAD_IMAGE: Symbol(`CHART_DOWNLOAD_IMAGE`),
  CHART_DOWNLOAD_DATA: Symbol(`CHART_DOWNLOAD_DATA`)
};

// Actions
const typePrefix = `modal/`;
const SHOW = `${typePrefix}SHOW`;
const HIDE = `${typePrefix}HIDE`;

// Selectors

export const getModal = state => state.modal;
export const getModalType = state => state.modal.modalType;
export const getModalProps = state => state.modal.modalProps;

// Action creators

export const showModal = ({ modalType, modalProps }) => ({
  type: SHOW,
  modalType,
  modalProps
});

export const hideModal = () => ({ type: HIDE });

/**
 * Download requests typically happen as part of a modal flow. Decide what
 * should happen for a particular download request and what should happen
 * after the request is fulfilled.
 *
 * @param {*} user
 */
export const requestDownload = (user, downloadType) => (dispatch, getState) => {
  dispatch(setUser(user));
  const state = getState();
  const modalType = getModalType(state);
  let nextAction = showModal;
  let nextActionProps = { modalType: modals.DOWNLOAD_ERROR };

  switch (modalType) {
    case modals.DOWNLOAD_REPORT:
      nextAction = prepReportDownload;
      nextActionProps = {};
      break;
    case modals.DOWNLOAD_CHART:
    case modals.DOWNLOAD_CHART_AGREED:
      if (downloadType === chartDownloadTypes.CHART_DOWNLOAD_DATA) {
        nextAction = prepChartDataDownload;
      } else {
        nextAction = prepChartImageDownload;
      }
      nextActionProps = {};
      break;
  }
  dispatch(nextAction(nextActionProps));
};

// Make a request to mailchimp
export const mailchimpRequest = (email, wantsNewsletter) => {
  const MAILCHIMP_NEWSLETTER = process.env.GATSBY_MAILCHIMP_NEWSLETTER;
  const MAILCHIMP_NO_NEWSLETTER = process.env.GATSBY_MAILCHIMP_NO_NEWSLETTER;
  const signupUrl = wantsNewsletter
    ? MAILCHIMP_NEWSLETTER
    : MAILCHIMP_NO_NEWSLETTER;
  return unReq({
    data: { email },
    method: `post`,
    url: signupUrl
  })
    .then(res => res)
    .catch(err => {
      // Sometimes these are invalid/duplicate address errors, sometimes other
      // errors. We can't tell the difference for now, so just ignore.
      console.log(`err`, err); // eslint-disable-line no-console
    });
};

// Reducer

const initialState = {
  isOpen: false,
  modalType: null,
  modalProps: {}
};

export function modalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW:
      return {
        isOpen: true,
        modalType: action.modalType,
        modalProps: action.modalProps
      };
    case HIDE:
      return { ...state, isOpen: false };
    default:
      return state;
  }
}
