import React from "react";
import ReactModal from "react-modal";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

import styles from "./index.module.css";
import PrimaryButton from "../PrimaryButton";
import Svg from "../Svg";
import closeSvg from "./close.svg";
import searchSvg from "./search.svg";
import saveSvg from "./save.svg";
import downloadSvg from "./download.svg";
import storage from "../../utils/storage";

const delay = 90; // Seconds until onboarding modal is shown
const storageKey = `onboarded`;

const IntroPanel = ({ close, hideIntro }) => (
  <div className={styles.panel}>
    <div className={styles.header}>
      <div className={styles.title}>
        Let us quickly tell you about our 3 helpful features:
      </div>
      <button onClick={() => close()} className={styles.closeButton}>
        <Svg svg={closeSvg} />
      </button>
    </div>
    <div className={styles.features} onClick={hideIntro} role="button">
      <div className={styles.featuresInner}>
        <div className={styles.feature}>
          <div className={styles.featureSvg}>
            <Svg svg={searchSvg} />
          </div>
          <div className={styles.featureLabel}>Search</div>
        </div>
        <div className={styles.feature}>
          <div className={styles.featureSvg}>
            <Svg svg={saveSvg} />
          </div>
          <div className={styles.featureLabel}>Save</div>
        </div>
        <div className={styles.feature}>
          <div className={styles.featureSvg}>
            <Svg svg={downloadSvg} />
          </div>
          <div className={styles.featureLabel}>Download</div>
        </div>
      </div>
    </div>
    <div className={styles.actions}>
      <div className={styles.action}>
        <PrimaryButton outline onClick={() => close(true)}>
          Ask me later
        </PrimaryButton>
      </div>
      <div className={styles.action}>
        <PrimaryButton dark onClick={hideIntro}>
          Sure
        </PrimaryButton>
      </div>
    </div>
    <div className={styles.noThanks}>
      <button onClick={() => close()} className={styles.textButton}>
        No thanks
      </button>
    </div>
  </div>
);

IntroPanel.propTypes = {
  close: PropTypes.func,
  hideIntro: PropTypes.func
};

const Panel = ({ close, data, panel }) => (
  <div className={styles.panel}>
    <div className={styles.header}>
      <div className={styles.title}>{data.title}</div>
      <button onClick={() => close()} className={styles.closeButton}>
        <Svg svg={closeSvg} />
      </button>
    </div>
    <div className={styles.inner}>
      <div className={styles.position}>
        {[0, 1, 2].map(item => (
          <div
            key={item}
            className={
              item === panel ? styles.positionItemSelected : styles.positionItem
            }
          />
        ))}
      </div>
      <div className={styles.description}>{data.description}</div>
    </div>
    <div className={styles.actions}>
      <div className={styles.action}>
        <PrimaryButton dark onClick={data.action}>
          {data.actionLabel}
        </PrimaryButton>
      </div>
    </div>
    <div className={styles.noThanks}>
      <button onClick={() => close()} className={styles.textButton}>
        Dismiss
      </button>
    </div>
  </div>
);

Panel.propTypes = {
  close: PropTypes.func,
  panel: PropTypes.number,
  data: PropTypes.shape({})
};

const Images = ({
  panel,
  search,
  searchMob,
  save,
  saveMob,
  download,
  downloadMob,
  panels
}) => (
  <div className={styles.images}>
    <div
      className={styles.imagesInner}
      style={{ transform: `translateX(-${panel * 100}%)` }}
    >
      <div className={styles.image}>
        <Img
          className={styles.searchImageDesktop}
          alt={panels[0].description}
          fluid={search.childImageSharp.fluid}
        />
        <Img
          className={styles.searchImageMob}
          alt={panels[0].description}
          fluid={searchMob.childImageSharp.fluid}
        />
      </div>
      <div className={styles.image}>
        <Img
          className={styles.saveImageDesktop}
          alt={panels[1].description}
          fluid={save.childImageSharp.fluid}
        />
        <Img
          className={styles.saveImageMob}
          alt={panels[1].description}
          fluid={saveMob.childImageSharp.fluid}
        />
      </div>
      <div className={styles.image}>
        <Img
          className={styles.downloadImageDesktop}
          alt={panels[2].description}
          fluid={download.childImageSharp.fluid}
        />
        <Img
          className={styles.downloadImageMob}
          alt={panels[2].description}
          fluid={downloadMob.childImageSharp.fluid}
        />
      </div>
    </div>
  </div>
);

Images.propTypes = {
  close: PropTypes.func,
  panel: PropTypes.number,
  panels: PropTypes.array,
  search: PropTypes.shape({}),
  save: PropTypes.shape({}),
  download: PropTypes.shape({}),
  searchMob: PropTypes.shape({}),
  saveMob: PropTypes.shape({}),
  downloadMob: PropTypes.shape({})
};

class Onboarding extends React.Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.hideIntro = this.hideIntro.bind(this);
    this.next = this.next.bind(this);

    this.state = {
      open: false,
      intro: true,
      panel: 0,
      panels: [
        {
          title: `Search`,
          description: `Easily find articles or charts`,
          actionLabel: `Next`,
          action: this.next
        },
        {
          title: `Saved articles`,
          description: `Bookmark your favourite articles`,
          actionLabel: `Next`,
          action: this.next
        },
        {
          title: `Download charts`,
          description: `Download images or CSVs for your own use`,
          actionLabel: `Got it!`,
          action: () => this.close()
        }
      ]
    };
  }

  componentDidMount() {
    if (storage.getItem(storageKey) === null) {
      this.timeout = setTimeout(() => this.open(), delay * 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.trigger !== this.props.trigger &&
      this.props.trigger === true
    ) {
      this.open();
    }
  }

  open() {
    this.setState({ open: true });
    storage.setItem(storageKey, `yes`);
  }

  close(remindMe) {
    this.setState({ open: false, panel: 0, intro: true });
    if (remindMe) {
      storage.removeItem(storageKey);
    }
    if (this.props.onClose && this.props.trigger === true) {
      this.props.onClose();
    }
  }

  hideIntro() {
    this.setState({ intro: false });
  }

  next() {
    this.setState({ panel: this.state.panel + 1 });
  }

  render() {
    return (
      <ReactModal
        className={styles.modal}
        contentLabel="Onboarding"
        isOpen={this.state.open}
        onRequestClose={this.close}
        overlayClassName={{
          base: styles.overlay,
          afterOpen: styles.overlayAfterOpen,
          beforeClose: styles.overlayBeforeClose
        }}
        parentSelector={() => document.body}
        shouldCloseOnOverlayClick={true}
        modalAppElement="#___gatsby"
        closeTimeoutMS={200}
      >
        {this.state.intro && (
          <IntroPanel close={this.close} hideIntro={this.hideIntro} />
        )}
        {!this.state.intro && (
          <>
            {this.state.panel === 0 && (
              <Panel panel={0} close={this.close} data={this.state.panels[0]} />
            )}
            {this.state.panel === 1 && (
              <Panel panel={1} close={this.close} data={this.state.panels[1]} />
            )}
            {this.state.panel === 2 && (
              <Panel panel={2} close={this.close} data={this.state.panels[2]} />
            )}
            <Images
              {...this.props.data}
              panel={this.state.panel}
              panels={this.state.panels}
            />
          </>
        )}
      </ReactModal>
    );
  }
}

Onboarding.propTypes = {
  data: PropTypes.shape({})
};

const OnboardingContainer = props => (
  <StaticQuery
    query={graphql`
      {
        save: file(relativePath: { eq: "Onboarding/save.png" }) {
          childImageSharp {
            fluid(maxWidth: 570) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        saveMob: file(relativePath: { eq: "Onboarding/save-mob.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        search: file(relativePath: { eq: "Onboarding/search.png" }) {
          childImageSharp {
            fluid(maxWidth: 570) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        searchMob: file(relativePath: { eq: "Onboarding/search-mob.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        download: file(relativePath: { eq: "Onboarding/download.png" }) {
          childImageSharp {
            fluid(maxWidth: 570) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        downloadMob: file(relativePath: { eq: "Onboarding/download-mob.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <Onboarding data={data} {...props} />}
  />
);

export default OnboardingContainer;
