import React from "react";
import PropTypes from "prop-types";
import Svg from "../Svg";
import selectIcon from "./selectIcon.svg";
import stylesSmall from "./small.module.css";
import stylesLarge from "./large.module.css";

const SelectControl = ({
  label,
  values,
  selectedId,
  onChange,
  selectableValues,
  size
}) => {
  const styles = size === `large` ? stylesLarge : stylesSmall;
  return (
    <div className={styles.root}>
      {selectableValues ? (
        <label className={styles.label}>
          {label}
          <div className={styles.elementWrapper}>
            <select
              className={styles.element}
              onChange={onChange}
              value={selectedId}
            >
              {values.map(value => (
                <option key={value.id} value={value.id}>
                  {value.name}
                </option>
              ))}
            </select>
            <span className={styles.icon}>
              <Svg className={styles.icon} svg={selectIcon} />
            </span>
          </div>
        </label>
      ) : (
        <div className={styles.label}>
          {label}: {values.find(value => value.id === selectedId).name}
        </div>
      )}
    </div>
  );
};

SelectControl.defaultProps = {
  selectableValues: true
};

SelectControl.propTypes = {
  size: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string
    })
  ),
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  selectableValues: PropTypes.bool
};

export default SelectControl;
