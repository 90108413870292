import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

import store from "../store";

// implement Gatsby's 'wrapRootElement' API for use both in gatsby-browser
// and gatsby-node. See https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redux

const wrapWithProvider = ({ element }) => (
  <Provider store={store}>{element}</Provider>
);

wrapWithProvider.propTypes = {
  element: PropTypes.element
};

export default wrapWithProvider;
