import React from "react";
import styles from "./index.module.css";

const Cookies = () => (
  <div className={styles.root}>
    <div className={styles.inner}>
      <h1 className={styles.heading}>Cookie Policy</h1>
      <p className={styles.body}>
        Our website uses cookies. By using our website and agreeing to this
        policy, you consent to our use of cookies in accordance with the terms
        of this policy.
      </p>
      <h2 className={styles.subHeading}>About Cookies</h2>
      <p className={styles.body}>
        Cookies are a kind of short-term memory for the web. They are stored in
        your browser and enable a site to ‘remember’ little bits of information
        between pages or visits.
      </p>
      <p className={styles.body}>
        Cookies can be used by web servers to identify and track users as they
        navigate different pages on a website, and to identify users returning
        to a website. Cookies may be either “persistent” cookies or “session”
        cookies. A persistent cookie consists of a text file sent by a web
        server to a web browser, which will be stored by the browser and will
        remain valid until its set expiry date (unless deleted by the user
        before the expiry date). A session cookie, on the other hand, will
        expire at the end of the user session, when the web browser is closed.
      </p>

      <h2 className={styles.subHeading}>Cookies on our website</h2>
      <p className={styles.body}>
        WWe use both session cookies and persistent cookies on our website.
      </p>

      <h2 className={styles.subHeading}>How we use cookies</h2>
      <p className={styles.body}>
        Cookies do not contain any information that personally identifies you,
        but personal information that we store about you may be linked, by us,
        to the information stored in and obtained from cookies. The cookies used
        on this website include those which are strictly necessary cookies for
        access and navigation, cookies that track usage (performance cookies)
        and remember your choices (functionality cookies). We may use the
        information we obtain from your use of our cookies for the following
        purposes:
      </p>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          to recognise your computer when you visit our website,
        </li>
        <li className={styles.listItem}>
          to retain clients’ email addresses and passwords when they log in to
          our ‘Member’s Area,’
        </li>
        <li className={styles.listItem}>
          to track you as you navigate our website,
        </li>
        <li className={styles.listItem}>
          to improve the website’s usability i.e. our Live Chat application to
          answer questions you have in real time,
        </li>
        <li className={styles.listItem}>
          to analyse the use of our website - such as how many people visit us
          each day, in the administration of our website.
        </li>
      </ul>

      <h2 className={styles.subHeading}>Third party cookies</h2>
      <p className={styles.body}>
        We work with the following companies in maintaining and improving our
        website. A brief description of what they do is found below. We ensure
        that any company which works with us and collects personal data complies
        with strict GDPR data protection standards. If you have any more
        questions, please do contact us and we’ll be happy to help.
      </p>

      <h2 className={styles.subHeading}>
        Google & Google Analytics Privacy Policy
      </h2>
      <p className={styles.body}>
        We use Google Analytics to better understand how our customers navigate
        to and through our website, how long customers spend visiting our
        content items and how often they return to visit our website. Google
        Analytics also helps us track the effectiveness of the money we spend on
        our digital marketing and social media campaigns.
      </p>
      <p className={styles.body}>
        Google’s GDPR compliant Privacy Policy, which contains information on
        cookies, can be found{` `}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>

      <h2 className={styles.subHeading}>Twitter Privacy and Cookie Policies</h2>
      <p className={styles.body}>
        Twitter instantly connects people everywhere to what’s most meaningful
        to them. Any registered user can send a Tweet, which is a message of 280
        characters or less that is public by default and can include other
        content like photos, videos and links to other websites.
      </p>
      <p className={styles.body}>
        We utilise data from Twitter Analytics for historical research to
        identify and learn from patterns and trends that occurred in the past to
        help us improve our social media marketing and generate better results
        for our digital marketing clients.
      </p>
      <p className={styles.body}>
        You can find Twitter’s GDPR compliant Privacy Policy, which covers their
        use of Cookies in section 2.3,{` `}
        <a
          href="https://twitter.com/en/privacy#update"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . For further information on Twitter’s Cookie Policy click{` `}
        <a
          href="https://help.twitter.com/en/rules-and-policies/twitter-cookies"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>

      <h2 className={styles.subHeading}>
        Facebook Privacy and Cookie Policies
      </h2>
      <p className={styles.body}>
        We utilise data from Facebook Insights for historical research to
        identify and learn from patterns and trends that occurred in the past to
        help us improve our social media marketing and generate better results
        for our digital marketing clients
      </p>
      <p className={styles.body}>
        You can find Facebook’s GDPR compliant Privacy Policy, which covers
        their Cookie Policy under the ‘Collection of Information’ heading{` `}
        <a
          href="https://www.facebook.com/legal/FB_Work_Privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . For further information on Facebook’s Cookie Policy, you can find
        their Workplace Cookies Statement{` `}
        <a
          href="https://fb.facebook.com/legal/FB_Work_Cookies"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>

      <h2 className={styles.subHeading}>
        LinkedIn Privacy and Cookie Policies
      </h2>
      <p className={styles.body}>
        Professional social networking site LinkedIn use cookies and similar
        technologies, including mobile application identifiers, to help them
        recognise you across different services, learn about your interests both
        on and off their services, improve your experience, increase security,
        measure use and improve the effectiveness of their services.
      </p>

      <p className={styles.body}>
        They use cookies on their websites (www.linkedin.com and slideshare.net)
        and mobile applications. Any browser loading these sites will receive
        cookies from LinkedIn. For LinkedIn members and SlideShare users, they
        also place cookies in your browser when you visit non-LinkedIn sites
        that host their plugins (for example, LinkedIn’s “Share” button) unless
        you opt out. If you’re not a LinkedIn member, they will not place
        cookies in your browser through our plugins.
      </p>

      <p className={styles.body}>
        We utilise data from LinkedIn Analytics for historical research to
        identify and learn from patterns and trends that occurred in the past to
        help us improve our social media marketing and generate better results
        for our digital marketing clients.
      </p>

      <p className={styles.body}>
        You can find LinkedIn’s GDPR compliant Privacy Policy, which covers
        Cookies in section 1.4,{` `}
        <a
          href="https://www.linkedin.com/legal/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . If you would like to know more about LinkedIn’s Cookie Policy click
        {` `}
        <a
          href="https://www.linkedin.com/legal/cookie-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>

      <h2 className={styles.subHeading}>Buffer Privacy and Cookie Policies</h2>
      <p className={styles.body}>
        We use Buffer.com to schedule social media posts for our digital
        marketing clients in advance. We also utilise data from Buffer Analytics
        for historical research to identify and learn from patterns and trends
        that occurred in the past to help us improve our social media marketing
        and generate better results for our digital marketing clients. Buffer’s
        Privacy Policy, which contains a section called ‘Cookies and
        Automatically Collected Information’, can be found{` `}
        <a
          href="https://buffer.com/privacyy"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . GDPR is an EU regulation that gives EU Data Subjects (individuals in
        the EU) control over their Data. The Buffer services are hosted in the
        United States but, as GDPR stipulates, countries outside the European
        Union, like the United States, have to comply with GDPR when processing,
        collecting, storing, deleting, using, combining and disclosing personal
        data of EU Data Subjects.
      </p>

      <h2 className={styles.subHeading}>Blocking cookies</h2>
      <p className={styles.body}>
        Most browsers allow you to refuse to accept cookies. For example:
      </p>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          In Internet Explorer 11 you can refuse all cookies by clicking
          “Tools”, “Internet Options”, select the “Privacy” tab and, locate
          “Advanced” under Settings and choose if you want to allow, block or be
          promoted for first and third-party cookies.
        </li>
        <li className={styles.listItem}>
          In Microsoft Edge click on the three dots icon in the top right-hand
          corner and select “Settings”. Locate “View advanced settings”. In the
          “Cookies” section you will find a drop-down menu where you can select
          “Block only third-party cookies” or “Block all cookies”. You will then
          have to re-start Microsoft Edge.
        </li>
        <li className={styles.listItem}>
          In Firefox you can block all cookies by clicking “Tools”, “Options”,
          and unchecking “Accept cookies from sites” in the “Privacy” box.
        </li>
        <li className={styles.listItem}>
          In Google Chrome you can adjust your cookie permissions by clicking on
          the three dots icon in the top right-hand corner of your browser
          screen, selecting “Settings” from near the bottom of the drop-down
          list, then clicking on “Advanced” near the bottom. This should open
          options. In the “Privacy” section, click “Content Settings” and
          disable cookies by selecting “Block site from setting any data”. Click
          “Done” to save your preferences.
        </li>
        <li className={styles.listItem}>
          On Safari on Apple OS X click on the Safari menu, then “Preferences”
          then “Privacy”. Under “Accept Cookies” unselect the “Always allow”
          option.
        </li>
        <li className={styles.listItem}>
          For Opera choose “Settings”, “Quick Preferences” and uncheck “Enable
          Cookies”.
        </li>
      </ul>

      <p className={styles.body}>
        Blocking all cookies will, however, have a negative impact upon the
        usability of many websites. If you block cookies, you may not be able to
        use certain features on our website (log on, access content, use search
        functions).
      </p>

      <h2 className={styles.subHeading}>Deleting cookies</h2>
      <p className={styles.body}>
        You can also delete cookies already stored on your computer:
      </p>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          In Internet Explorer 11 click on “Tools”, point to “Safety” and then
          select the “Delete browsing history”. Click on the “Cookies and
          website data” checkbox and then choose “Delete”.
        </li>
        <li className={styles.listItem}>
          In Microsoft Edge click on the three dots icon in the top right-hand
          corner and select “Settings”. Under “Clear browsing data” select
          “Choose what to clear” and select the checkbox next to “Cookies and
          saved website data” then click on “Clear”. If you wish you can turn
          the “Always clear this when I close the browser” setting to “On”.
        </li>
        <li className={styles.listItem}>
          To delete cookies from a particular website in Firefox, click on the
          three lines icon and choose “Options”. In the “Privacy & Security”
          panel go to the “History” section and in the drop menu next to
          “Firefox will” choose “Use custom settings for history”. A cookies
          window will appear when you click “Show Cookies”. In the Search field
          type the name of the site whose cookies you want to remove. Select the
          cookies you wish to remove and click “Remove Selected”. Click “Close”
          to close the Cookies window and the “about:preferences” page.
          <ul className={styles.list}>
            <li className={styles.listItem}>
              To delete all cookies, open the cookies window as above and click
              “Remove All Cookies”.
            </li>
          </ul>
        </li>
        <li className={styles.listItem}>
          In Google Chrome click on the three dots icon in the top right-hand
          corner, select “Settings” and find “Advanced” near the bottom. Under
          “Privacy and security” click on “Content settings”. Click on “Cookies”
          and Under “All cookies and site data” select “Remove All”. Confirm by
          clicking on “Clear all”.
        </li>
        <li className={styles.listItem}>
          On Safari on Apple OS X click on the Safari menu, then select
          “Preferences” from the drop-down. Go to the “Privacy” tab. Click the
          “Remove All Website Data” button to remove all stored website data and
          click “Remove Now” to confirm. To remove stored data by individual
          site, click on the “Details” button and select the sites with the
          stored data you wish to remove. Click “Remove” and then “Done”.
        </li>
        <li className={styles.listItem}>
          For Opera selecting “Settings”, “Preferences”, “Advanced” and then
          “Cookies” and checking the “Delete new cookies when exiting Opera” and
          closing Opera when you have finished browsing will ensure the cookie
          information is kept in the browser.
        </li>
      </ul>
    </div>
  </div>
);

export default Cookies;
