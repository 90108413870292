import "typeface-barlow";
import provideRedux from "./src/utils/provideRedux";

export const wrapRootElement = provideRedux;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  // magic - transition length is defined elsewhere as 500ms
  // wait until transition nearly complete before changing scroll position
  // allows out going page to disappear before adjusting scroll position for incoming page
  // this will not be visible as the incoming page is set to positing fixed until
  // the animation is complete
  let transitionDelay = 400;
  if (location.action === `PUSH`) {
    window.setTimeout(() => window.scrollTo({ top: 0 }), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }
  return false;
};

export const onClientEntry = async () => {
  // wrap pushstate so we can trigger a listener from it
  let pushState = history.pushState;
  history.pushState = function() {
    pushState.apply(history, arguments); // eslint-disable-line prefer-rest-params
    if (window && window.___setActivePathname) {
      window.___setActivePathname(arguments[2]); // eslint-disable-line prefer-rest-params
    }
  };

  // If required — e.g. Safari — add the IntersectionObserver polyfill.
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }

  // shim requestIdleCallback for browsers that don't support it
  window.requestIdleCallback =
    window.requestIdleCallback ||
    function(cb) {
      const start = Date.now();
      return setTimeout(() => {
        cb({
          didTimeout: false,
          timeRemaining: () => Math.max(0, 50 - (Date.now() - start))
        });
      }, 1);
    };

  window.cancelIdleCallback =
    window.cancelIdleCallback ||
    function(id) {
      clearTimeout(id);
    };
};
