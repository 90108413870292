import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

import NavArticle from "../NavArticle";

const NavArticleList = ({ previews, activePathname, closeReportNav }) => (
  <div className={styles.root}>
    <div className={styles.list}>
      {previews.map(navArticle => {
        return (
          <NavArticle
            key={navArticle.entityId}
            {...navArticle}
            isActive={activePathname === navArticle.path}
            closeReportNav={closeReportNav}
          />
        );
      })}
    </div>
  </div>
);

NavArticleList.propTypes = {
  activePathname: PropTypes.string,
  previews: PropTypes.arrayOf(PropTypes.shape({})),
  selectedId: PropTypes.number,
  closeReportNav: PropTypes.func
};

export default NavArticleList;
