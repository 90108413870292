import React from "react";
import PropTypes from "prop-types";
import Svg from "../Svg";
import loadingIcon from "./LoadingIcon.svg";
import styles from "./index.module.css";

const Loading = () => (
  <div className={styles.loadingInner}>
    <Svg className={styles.loadingSVG} svg={loadingIcon} height="50px" />
  </div>
);

const BlockVideo = ({ id }) => (
  <div className={styles.video}>
    {/* <iframe
      className={styles.videoInner}
      src={`https://player.vimeo.com/video/${id}?color=18f5a8&title=0&byline=0&portrait=0`}
      width="640"
      height="360"
      frameBorder="0"
      allowFullScreen
    /> */}
    <div className={styles.loading}>
      <Loading />
    </div>
  </div>
);

BlockVideo.propTypes = {
  id: PropTypes.string
};

export default BlockVideo;
