import { connect } from "react-redux";
import ModalRoot from "../ModalRoot";

import { getModal, hideModal } from "../../state/modal";

const withRedux = connect(
  state => ({
    modal: getModal(state)
  }),
  dispatch => ({
    onClose() {
      dispatch(hideModal());
    }
  })
);

export default withRedux(ModalRoot);
