import storage from "../../utils/storage";

// Actions
const typePrefix = `user/`;
const CLEAR = `${typePrefix}CLEAR`;
const SET = `${typePrefix}SET`;

// Selectors

export const getUser = state => state.user;

// Action creators

/**
 *
 * @return {function} thunk action creator
 */
export const setUser = ({ email, newsletter, termsAgreed }) => (
  dispatch,
  getState
) => {
  dispatch({
    type: SET,
    email,
    newsletter,
    termsAgreed
  });
  const serialised = JSON.stringify(getState().user);
  storage.setItem(typePrefix, serialised);
};

export const clearUser = () => dispatch => {
  dispatch({ type: CLEAR });
  const serialised = JSON.stringify(emptyState);
  storage.setItem(typePrefix, serialised);
};

const emptyState = {
  email: null,
  newsletter: false,
  termsAgreed: false
};

/**
 * Rehydrate the state from storage.
 * @return {object} Previously saved auth state or an empty object
 */
export const rehydrate = () => {
  const user = storage.getItem(typePrefix);
  const hydrated =
    typeof user === `string` ? JSON.parse(user) : { ...emptyState };
  return hydrated;
};

// Reducer
export function userReducer(state = rehydrate(), action = {}) {
  switch (action.type) {
    case SET:
      return {
        email: action.email,
        newsletter: action.newsletter,
        termsAgreed: action.termsAgreed
      };
    case CLEAR:
      return { ...emptyState };
    default:
      return state;
  }
}
