import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";

import SplashContainer from "../Splash/container";
import DataPartners from "../DataPartners";
import Seo from "../Seo";
import KeyFindingsContents from "../KeyFindingsContents";
import KeyFindings from "../KeyFindings";
import CountryHighlights from "../CountryHighlights";
import Footer from "../Footer";
import keyFindingsData from "./key-findings-data.json";
import countryHighlightsData from "./country-highlights-data.json";
import styles from "./index.module.css";

const HomeWithoutStaticQuery = ({ reportPath }) => (
  <div className={styles.root}>
    <Seo />
    <SplashContainer reportPath={reportPath} />
    <DataPartners />
    <KeyFindingsContents titles={keyFindingsData.map(item => item.title)} />
    <KeyFindings data={keyFindingsData} />
    <CountryHighlights
      index={keyFindingsData.length + 1}
      data={countryHighlightsData}
    />
    <Footer reportPath={reportPath} />
  </div>
);

HomeWithoutStaticQuery.propTypes = {
  reportPath: PropTypes.string
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        soetChapter {
          path
        }
      }
    `}
    render={data => (
      <HomeWithoutStaticQuery reportPath={data.soetChapter.path} {...props} />
    )}
  />
);
