import React from "react";
// import PropTypes from "prop-types";

import { Hits, connectStateResults } from "react-instantsearch-dom";

import styles from "./index.module.css";

const ResultsWrapper = connectStateResults(
  ({ searchState, searchResults, searching, hitComponent }) => {
    const hasResults = !!searchResults && searchResults.nbHits !== 0;
    const hasQuery = !!searchState && !!searchState.query;
    return (
      <React.Fragment>
        <div hidden={hasQuery || searching} className={styles.resultsInitial}>
          <span>Start searching to see results</span>
        </div>
        <div
          hidden={hasResults || (!hasResults && !hasQuery) || searching}
          className={styles.resultsEmpty}
        >
          <span>No results found for {searchState.query}</span>
        </div>
        <div hidden={!searching} className={styles.resultsSearching}>
          <span>Searching...</span>
        </div>
        <div
          className={styles.resultsContainer}
          hidden={!hasResults || (!hasQuery && hasResults) || searching}
        >
          <Hits hitComponent={hitComponent} />
        </div>
      </React.Fragment>
    );
  }
);

export default ResultsWrapper;
