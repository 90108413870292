import { connect } from "react-redux";
import DownloadSuccess from "./";

import { hideModal } from "../../state/modal";
import { getUser } from "../../state/user";

const withRedux = connect(
  state => ({
    email: getUser(state).email
  }),
  dispatch => ({
    onOk() {
      dispatch(hideModal());
    }
  })
);

export default withRedux(DownloadSuccess);
