import React from "react";
import PropTypes from "prop-types";
import PrimaryButton from "../PrimaryButton";
import { ShareLinkedIn, ShareTwitter } from "../Share";
import { StaticQuery, graphql } from "gatsby";
import styles from "./index.module.css";

import squirrel from "./secretSquirrel.png";

const EndPlate = ({ onDownloadClick, siteUrl }) => {
  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <h1 className={styles.title}>{`That's all folks`}</h1>
        <hr className={styles.hr} />
        <h2 className={styles.finalTitle}>{`Thanks for reading`}</h2>
        <div className={styles.actions}>
          <PrimaryButton onClick={onDownloadClick}>
            Download Report
          </PrimaryButton>
          <div className={styles.shareButtons}>
            <div className={styles.shareIcon}>
              <ShareLinkedIn
                className={styles.shareIcon}
                url={`${siteUrl}`}
                sourceUrl={siteUrl}
                title={`The State of European Tech`}
                large={true}
                inverted={true}
              />
            </div>
            <div className={styles.shareIcon}>
              <ShareTwitter
                text={`The State of European Tech`}
                url={siteUrl}
                large={true}
                inverted={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.squirrel}>
        <a
          href="https://docs.google.com/document/d/1EJpSFmY3-uHr2U-Oft-A0vO3kAUgz3TXjWj06nHaHkU/edit?ts=5bf7b946"
          target="_blank"
          rel="noopener noreferrer"
          title="Secret Squirrel"
        >
          <img className={styles.squirrelImage} src={squirrel} />
        </a>
      </div>
    </div>
  );
};

EndPlate.defaultProps = {
  onDownloadClick: () => {}
};

EndPlate.propTypes = {
  onDownloadClick: PropTypes.func,
  siteUrl: PropTypes.string
};

// TODO: run this query directly from the Share component instead of here?
export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteUrl: url
          }
        }
      }
    `}
    render={data => (
      <EndPlate siteUrl={data.site.siteMetadata.siteUrl} {...props} />
    )}
  />
);
