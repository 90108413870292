import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

import PrimaryButton from "../PrimaryButton";

import { chartDownloadTypes } from "../../state/modal";

class DownloadChartAgreed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.user.email || ``,
      newsletter: this.props.user.newsletter === false ? false : true,
      termsAgreed: this.props.user.termsAgreed || false,
      isSubmitted: false
    };

    this.downloadImage = this.downloadImage.bind(this);
    this.downloadData = this.downloadData.bind(this);
  }

  downloadImage(e) {
    this.setState({ isSubmitted: true });
    e.preventDefault();
    this.props.onSubmit(this.state, chartDownloadTypes.CHART_DOWNLOAD_IMAGE);
  }

  downloadData(e) {
    this.setState({ isSubmitted: true });
    e.preventDefault();
    this.props.onSubmit(this.state, chartDownloadTypes.CHART_DOWNLOAD_DATA);
  }

  render() {
    return (
      <div className={styles.root}>
        <h1 className={styles.title}>Downlod Chart</h1>
        <p className={styles.body}>
          You can download the raw data or the chart image as displayed on this
          site.
        </p>
        <div className={styles.submit}>
          <div className={styles.downloadButton}>
            <PrimaryButton onClick={this.downloadData} dark={true}>
              Download Data
            </PrimaryButton>
          </div>
          <div className={styles.downloadButton}>
            <PrimaryButton onClick={this.downloadImage} dark={true}>
              Download Image
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}

DownloadChartAgreed.defaultProps = {};

DownloadChartAgreed.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    termsAgreed: PropTypes.bool,
    newsletter: PropTypes.bool
  }),
  onSubmit: PropTypes.func
};

export default DownloadChartAgreed;
