import storage from "../../utils/storage";
import objectKeysMatch from "../../utils/objectKeysMatch";

// Actions
const typePrefix = `articles/save/`;
const CLEAR_SAVED = `${typePrefix}CLEAR_SAVED`;
const TOGGLE_SAVED = `${typePrefix}TOGGLE_SAVED`;
const SET_VALID = `${typePrefix}SET_VALID`;
const CLEAR_VALID = `${typePrefix}CLEAR_VALID`;

// Selectors

export const getSaved = state => state.articles.save;
export const getSavedArticleIds = state => state.articles.save.savedIds;
export const getSavedCount = state => state.articles.save.savedIds.length;
export const getValidArticleIds = state => state.articles.save.validIds;
// export const getSelectedArticleId = state =>
//   state.articles.save.selectedArticleId;

// Action creators

/**
 *
 * @param {string} articleId the article Id to be toggled
 * @return {function} thunk action creator
 */
export const toggleArticle = articleId => (dispatch, getState) => {
  dispatch({
    type: TOGGLE_SAVED,
    articleId
  });
  const serialised = JSON.stringify(getSaved(getState()));
  storage.setItem(`savedArticles`, serialised);
};

/**
 * Saved article ids might become out of sync with which article ids exist.
 * Call this with a set of valid article ids after the app has mounted to
 * purge any invalid ids from the saved ids array.
 *
 * @param {array} articleIds the article Ids that are valid
 * @return {function} thunk action creator
 */
export const setValidIds = articleIds => (dispatch, getState) => {
  dispatch({
    type: SET_VALID,
    articleIds
  });
  const serialised = JSON.stringify(getSaved(getState()));
  storage.setItem(`savedArticles`, serialised);
};

export const emptyState = {
  savedIds: [],
  validIds: []
};

/**
 * Rehydrate the state from storage.
 * @return {object} Previously saved auth state or an empty object
 */
export const rehydrate = () => {
  const saved = storage.getItem(`savedArticles`);
  let hydrated = emptyState;
  if (typeof saved === `string` && saved !== `undefined`) {
    try {
      hydrated = JSON.parse(saved);
    } catch (e) {
      hydrated = emptyState;
      // eslint-disable-next-line no-console
      console.log(`Could not rehydrate from storage`, e);
    }
  }
  // guard against unusual state coming back from storage
  if (!objectKeysMatch(emptyState, hydrated)) return emptyState;
  return hydrated;
};

// Reducer
export function saveReducer(state = rehydrate(), action = {}) {
  const { savedIds } = state;
  switch (action.type) {
    case TOGGLE_SAVED:
      // remove article
      if (savedIds.includes(action.articleId)) {
        return {
          ...state,
          savedIds: savedIds.filter(id => id !== action.articleId)
        };
      }

      // add article
      return { ...state, savedIds: [...savedIds, action.articleId] };
    case CLEAR_SAVED:
      return { ...state, savedIds: [] };
    case SET_VALID:
      return {
        ...state,
        // TODO: create a separate action for this
        // remove savedIds that don't exist in validIds
        savedIds: savedIds.filter(id => action.articleIds.includes(id)),
        // save validIds
        validIds: action.articleIds
      };
    case CLEAR_VALID:
      return { ...state, validIds: [] };
    default:
      return state;
  }
}

/**
 * Clears saved article data
 * @return {function} Thunk
 */
// export const unpersist = () => dispatch => {
//   storage.removeItem("savedArticles");
//   dispatch({ type: UNPERSIST });
// };
