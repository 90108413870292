import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import CloseButton from "../CloseButton";
import ReactModal from "react-modal";

class Modal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const modalAppElement = this.props.modalAppElement;
    if (modalAppElement) ReactModal.setAppElement(modalAppElement);
  }

  render() {
    const { onAfterOpen, onRequestClose, isOpen, children } = this.props;

    return (
      <ReactModal
        className={styles.content}
        closeTimeoutMS={200}
        contentLabel="Modal"
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
        overlayClassName={{
          base: styles.overlay,
          afterOpen: styles.overlayAfterOpen,
          beforeClose: styles.overlayBeforeClose
        }}
        parentSelector={() => document.body}
        shouldCloseOnOverlayClick={true}
      >
        <div className={styles.button}>
          <CloseButton onClick={onRequestClose} />
        </div>
        {children}
      </ReactModal>
    );
  }
}

//

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func,
  modalAppElement: PropTypes.node
};

export default Modal;
