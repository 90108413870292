import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";

import Svg from "../Svg";
import TabChrome from "./TabChrome";
import ContentsPanel from "./ContentsPanel";
import SearchPanel from "../ReportNav/SearchPanel";

import styles from "./index.module.css";
import searchIcon from "./searchIcon.svg";

const ContentsTab = () => <React.Fragment>Contents</React.Fragment>;
const SearchTab = () => (
  <React.Fragment>
    <Svg svg={searchIcon} alt="Search" className={styles.searchTabIcon} />
    Search
  </React.Fragment>
);

const ReportNav = ({ data, location }) => {
  return (
    <React.Fragment>
      <TabChrome
        tabs={[<ContentsTab key="contents" />, <SearchTab key="search" />]}
      >
        <ContentsPanel
          chapters={data.chapters}
          allArticleIds={data.allArticleIds}
          location={location}
        />
        <SearchPanel />
      </TabChrome>
    </React.Fragment>
  );
};

ReportNav.propTypes = {
  data: PropTypes.shape({
    chapters: PropTypes.array
  }),
  location: PropTypes.shape({})
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        allSoetChapter(sort: { fields: [sortOrder], order: ASC }) {
          edges {
            node {
              id
              entityId
              path
              subTitle
              title
              titleNumber
              soetArticle {
                id
                entityId
                path
                title
                titleNumber
              }
            }
          }
        }
        allSoetArticle {
          edges {
            node {
              entityId
            }
          }
        }
      }
    `}
    render={data => (
      <ReportNav
        data={{
          chapters: data.allSoetChapter.edges.map(edge => edge.node),
          allArticleIds: data.allSoetArticle.edges.map(
            edge => edge.node.entityId
          )
        }}
        {...props}
      />
    )}
  />
);
