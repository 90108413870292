import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.css";
import PrimaryButton from "../PrimaryButton";
import KeyFindingsStats from "../KeyFindingsStats";
import KeyFindingsList from "../KeyFindingsList";
import KeyFindingsText from "../KeyFindingsText";
import Svg from "../Svg";

import svg1 from "./key-finding-1.svg";
import svg2 from "./key-finding-2.svg";
import svg3 from "./key-finding-3.svg";
import svg4 from "./key-finding-4.svg";
import svg5 from "./key-finding-5.svg";
import svg6 from "./key-finding-6.svg";

const images = [svg1, svg2, svg3, svg4, svg5, svg6];

const KeyFindings = ({ data }) => (
  <div className={styles.root}>
    {data.map((item, index) => {
      return (
        <div id={`finding-0${index + 1}`} key={index} className={styles.block}>
          <div className={styles.blockInner}>
            <div className={styles.titleWrapper}>
              <p className={styles.number}>0{index + 1}</p>
              <h2 className={styles.title}>{item.title}</h2>
            </div>
            <div className={styles.contentCols}>
              <div className={styles.contentCol}>
                <div className={styles.imgWrapper}>
                  <Svg svg={images[index]} className={styles.img} alt="" />
                </div>
              </div>
              <div className={styles.contentCol}>
                <div className={styles.contentBlock}>
                  {item.type === `text` && <KeyFindingsText data={item.data} />}
                  {item.type === `list` && <KeyFindingsList data={item.data} />}
                  {item.type === `stats` && (
                    <KeyFindingsStats data={item.data} />
                  )}
                </div>
              </div>
            </div>
            <nav className={styles.nav}>
              <div className={styles.navItem}>
                <PrimaryButton to={item.link} dark type="link">
                  Read more within report
                </PrimaryButton>
              </div>
            </nav>
          </div>
        </div>
      );
    })}
  </div>
);

KeyFindings.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      data: PropTypes.array.isRequired // passed to sub-components
    })
  )
};

export default KeyFindings;
