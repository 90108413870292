import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import Svg from "../Svg";
import titleize from "titleize";
import humanizeString from "humanize-string";

/* Import all logos - Make sure to add Colour & White to LOGOS and LOGOS_WHITE*/

import advancedhrLogo from "./logos/advancedhr.svg";
import advancedhrLogoWhite from "./logos/advancedhr-white.svg";
import atomicoLogo from "./logos/atomico.svg";
import atomicoLogoWhite from "./logos/atomico-white.svg";
import cbreLogo from "./logos/cbre.svg";
import cbreLogoWhite from "./logos/cbre-white.svg";
import cern from "./logos/cern.svg";
import cernWhite from "./logos/cern-white.svg";
import craft from "./logos/craft.svg";
import craftWhite from "./logos/craft-white.svg";
import dealroom from "./logos/dealroom.svg";
import dealroomWhite from "./logos/dealroom-white.svg";
import diversityVc from "./logos/diversityvc.svg";
import diversityVcWhite from "./logos/diversityvc-white.svg";
import eifLogo from "./logos/eif.svg";
import eifLogoWhite from "./logos/eif-white.svg";
import startupHeatmapLogo from "./logos/startup-heatmap.svg";
import startupHeatmapLogoWhite from "./logos/startup-heatmap-white.svg";
import indeedLogo from "./logos/indeed.svg";
import indeedLogoWhite from "./logos/indeed-white.svg";
import investEuropeLogo from "./logos/invest-europe.svg";
import investEuropeLogoWhite from "./logos/invest-europe-white.svg";
import linkedInLogo from "./logos/linkedin.svg";
import linkedInLogoWhite from "./logos/linkedin-white.svg";
import lseLogo from "./logos/lse.svg";
import lseLogoWhite from "./logos/lse-white.svg";
import meetUpLogo from "./logos/meetup.svg";
import meetUpLogoWhite from "./logos/meetup-white.svg";
import preqinLogo from "./logos/preqin.svg";
import preqinLogoWhite from "./logos/preqin-white.svg";
import quidLogo from "./logos/quid.svg";
import quidLogoWhite from "./logos/quid-white.svg";
import soetSurveyLogo from "./logos/soet-survey.svg";
import soetSurveyLogoWhite from "./logos/soet-survey-white.svg";
import stackOverflowLogo from "./logos/stackoverflow.svg";
import stackOverflowLogoWhite from "./logos/stackoverflow-white.svg";
import politicoLogo from "./logos/politico-pro.svg";
import politicoLogoWhite from "./logos/politico-pro-white.svg";

const LOGOS = {
  "advance-hr": advancedhrLogo,
  "diversity-vc": diversityVc,
  "startup-heatmap-europe": startupHeatmapLogo,
  "invest-europe": investEuropeLogo,
  "stack-overflow": stackOverflowLogo,
  "the-state-of-european-tech-survey-2018": soetSurveyLogo,
  "politico-pro": politicoLogo,
  atomico: atomicoLogo,
  cbre: cbreLogo,
  cern: cern,
  craft: craft,
  dealroomco: dealroom,
  eif: eifLogo,
  indeed: indeedLogo,
  linkedin: linkedInLogo,
  lse: lseLogo,
  meetup: meetUpLogo,
  quid: quidLogo,
  preqin: preqinLogo
};

const LOGOS_WHITE = {
  "advance-hr": advancedhrLogoWhite,
  "diversity-vc": diversityVcWhite,
  "startup-heatmap-europe": startupHeatmapLogoWhite,
  "invest-europe": investEuropeLogoWhite,
  "stack-overflow": stackOverflowLogoWhite,
  "the-state-of-european-tech-survey-2018": soetSurveyLogoWhite,
  "politico-pro": politicoLogoWhite,
  atomico: atomicoLogoWhite,
  cbre: cbreLogoWhite,
  cern: cernWhite,
  craft: craftWhite,
  dealroomco: dealroomWhite,
  eif: eifLogoWhite,
  indeed: indeedLogoWhite,
  linkedin: linkedInLogoWhite,
  lse: lseLogoWhite,
  meetup: meetUpLogoWhite,
  quid: quidLogoWhite,
  preqin: preqinLogoWhite
};

const LOGOS_TEXT = {
  "sp-global-market-intelligence": `S&P Global Market Intelligence`
};

const LOGOS_LINKS = {
  "advance-hr": `https://www.advanced-hr.com/`,
  "diversity-vc": `http://www.diversity.vc/`,
  "startup-heatmap-europe": `https://www.startupheatmap.eu/`,
  "invest-europe": `https://www.investeurope.eu/`,
  "politico-pro": `https://www.politico.eu/why-go-pro/`,
  "stack-overflow": `https://stackoverflow.com/`,
  "the-state-of-european-tech-survey-2018": `https://2018.stateofeuropeantech.com`,
  atomico: `https://www.atomico.com/`,
  cbre: `https://www.cbre.co.uk/`,
  cern: `http://kt.cern/`,
  craft: `https://craft.co/`,
  dealroomco: `https://dealroom.co/`,
  eif: `http://www.eif.org/`,
  indeed: `https://www.indeed.co.uk/`,
  linkedin: `https://www.linkedin.com/`,
  lse: `https://www.londonstockexchange.com/`,
  meetup: `https://www.meetup.com/`,
  quid: `https://quid.com/`,
  preqin: `https://www.preqin.com/`
};

const PartnerLogo = ({ partner, white }) => {
  const LOGO_SET = white ? LOGOS_WHITE : LOGOS;

  if (partner in LOGO_SET) {
    return (
      <a
        href={LOGOS_LINKS[partner]}
        target="_blank"
        rel="noopener noreferrer"
        title={partner}
      >
        <Svg className={styles.root} svg={LOGO_SET[partner]} alt={partner} />
      </a>
    );
  }

  // output the partner name as text instead of using a logo
  const partnerText = LOGOS_TEXT[partner] || titleize(humanizeString(partner));
  return <p className={styles.textLogo}>{partnerText}</p>;
};

PartnerLogo.propTypes = {
  partner: PropTypes.string,
  white: PropTypes.bool
};

export default PartnerLogo;
