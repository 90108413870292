import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

import SaveIcon from "../SaveIcon";

const Save = ({ active, onClick, withText }) => {
  const activeModifier = active ? `Active` : ``;
  const rootStyle = withText ? styles.rootWithText : styles.root;
  return (
    <button className={rootStyle} onClick={onClick}>
      {withText && <span className={styles.text}>Save</span>}
      <SaveIcon className={styles[`saveIcon${activeModifier}`]} />
    </button>
  );
};

Save.defaultProps = {
  active: false,
  onClick: () => {}
};

Save.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  withText: PropTypes.bool
};

export default Save;
