import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

import PrimaryButton from "../PrimaryButton";

const DownloadError = ({ onOk }) => (
  <div className={styles.root}>
    <h1 className={styles.title}>Oops!</h1>
    <p className={styles.body}>
      There was a problem with your download. Please try again later.
    </p>
    <div className={styles.button}>
      <PrimaryButton dark={true} onClick={onOk}>
        OK
      </PrimaryButton>
    </div>
  </div>
);

DownloadError.defaultProps = {
  email: `your address`
};

DownloadError.propTypes = {
  email: PropTypes.string,
  onOk: PropTypes.func
};

export default DownloadError;
