import React from "react";
import PropTypes from "prop-types";
import PrimaryButton from "../PrimaryButton";
import styles from "./index.module.css";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import PartnerLogo from "../PartnerLogo";

const data = {
  sponsors: [`Slush`, `Orrick`],
  dataPartners: [
    `advance-hr`,
    `cbre`,
    `cern`,
    `craft`,
    `dealroomco`,
    `eif`,
    `indeed`,
    `invest-europe`,
    `linkedin`,
    `lse`,
    `meetup`,
    `politico-pro`,
    `preqin`,
    `quid`,
    `stack-overflow`,
    `startup-heatmap-europe`
  ]
};

const getFilename = partnerName =>
  `${partnerName.toLowerCase().replace(/[.,\s]/g, ``)}.png`;

const DataPartnersWithoutStaticQuery = ({ images }) => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.contentCols}>
          <div className={styles.contentCol}>
            <h2 className={styles.title}>
              We are proud to present the single, most comprehensive data-driven
              analysis on European technology today.
            </h2>
          </div>
          <div className={styles.contentCol}>
            <p className={styles.smallerText}>
              We could not have created this report without our generous
              sponsors, an incredible set of data partners, and thousands of
              people in the region who have shared their insights. Thank you.
            </p>
          </div>
        </div>
        <div>
          <div>
            <p className={styles.subTitle}>In partnership with</p>
            <div className={styles.sponsorLogos}>
              {data.sponsors.map(sponsor => (
                <div className={styles.logo} key={sponsor}>
                  <Img
                    className={styles.logoImg}
                    alt={`${sponsor.name} logo`}
                    imgStyle={{ objectFit: `contain` }}
                    fluid={images.find(
                      image => image.originalName === getFilename(sponsor)
                    )}
                  />
                </div>
              ))}
            </div>
            <p className={styles.subTitle}>Our Data Partners</p>
            <div className={styles.logos}>
              {data.dataPartners.map(dataPartner => (
                <div className={styles.logo} key={dataPartner}>
                  <PartnerLogo partner={dataPartner} />
                </div>
              ))}
            </div>
            <nav className={styles.nav}>
              <div className={styles.navItem}>
                <PrimaryButton
                  to={`/chapter/about/article/methodology-data-partners/`}
                  dark
                  type="link"
                >
                  More on our partners
                </PrimaryButton>
              </div>
              <div className={styles.navItem}>
                <a href="mailto:info@atomico.com?subject=Becoming a State Of European Tech partner">
                  <PrimaryButton dark type="button">
                    Become a partner
                  </PrimaryButton>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

DataPartnersWithoutStaticQuery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({}))
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: { sourceInstanceName: { eq: "data-partner-logos" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 80, maxWidth: 120) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <DataPartnersWithoutStaticQuery
        images={data.allFile.edges.map(
          ({ node }) => node.childImageSharp.fluid
        )}
        {...props}
      />
    )}
  />
);
