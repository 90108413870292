import { connect } from "react-redux";
import SavedArticlesTab from "../SavedArticlesTab";

import { getSavedCount } from "../../../state/articles/save";

const withRedux = connect(state => ({
  savedCount: getSavedCount(state)
}));

export default withRedux(SavedArticlesTab);
