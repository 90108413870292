import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import rightArrow from "./rightArrow.svg";
import SVG from "../Svg";

import { Link } from "gatsby";

const PrimaryButton = ({
  onClick,
  children,
  disabled,
  type,
  to,
  inverted,
  dark,
  outline,
  arrow
}) => {
  const El = type === `link` || typeof to === `string` ? Link : `button`;
  let className = inverted ? styles.rootInverted : styles.root;
  className = dark ? styles.rootDark : className;
  className = arrow ? styles.rootArrow : className;
  className = outline ? styles.rootOutline : className;
  return (
    <El className={className} onClick={onClick} disabled={disabled} to={to}>
      {arrow && <SVG className={styles.arrow} svg={rightArrow} />}
      {children}
    </El>
  );
};

PrimaryButton.defaultProps = {
  disabled: false,
  type: `button`
};

PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf([`button`, `link`]),
  to: PropTypes.string,
  inverted: PropTypes.bool,
  dark: PropTypes.bool,
  arrow: PropTypes.bool,
  outline: PropTypes.bool
};

export default PrimaryButton;
