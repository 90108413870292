import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import FigureFooter from "../FigureFooter";

const BlockChartImage = ({ insight, title, image, sources, note, svg }) => {
  const src = svg && svg.indexOf(`.svg`) > -1 ? svg : image;
  return (
    <div className={styles.root}>
      {insight && <h1 className={styles.insight}>{insight}</h1>}
      {title && <h2 className={styles.title}>{title}</h2>}
      <img className={styles.image} alt={title} src={src} />
      <FigureFooter sources={sources} note={note} />
    </div>
  );
};

BlockChartImage.propTypes = {
  title: PropTypes.string,
  insight: PropTypes.string,
  sources: PropTypes.string,
  note: PropTypes.string,
  image: PropTypes.string,
  svg: PropTypes.string
};

export default BlockChartImage;
