import React from "react";
import styles from "./index.module.css";

const Error404 = () => (
  <div className={styles.root}>
    <div className={styles.inner}>
      <div className={styles.numbers}>404</div>
      <h1 className={styles.title}>NOT FOUND</h1>
      <p className={styles.copy}>
        Oops, we couldn&#39;t find the page you were looking for. It may have
        moved, or the entire European tech ecosystem may have unexpectedly
        imploded (but it&#39;s probably the former)
      </p>
    </div>
  </div>
);

export default Error404;
