import React from "react";
import PropTypes from "prop-types";
import SaveIcon from "../../SaveIcon";
import styles from "./index.module.css";
const SavedArticlesTab = ({ savedCount }) => {
  return (
    <React.Fragment>
      <SaveIcon
        className={savedCount > 0 ? styles.savedIconActive : styles.savedIcon}
      />
      <span className={styles.text}>Saved ({savedCount})</span>
    </React.Fragment>
  );
};

export default SavedArticlesTab;

SavedArticlesTab.propTypes = {
  savedCount: PropTypes.number
};

SavedArticlesTab.defaultProps = {
  savedCount: 0
};
