import React from "react";
import styles from "./wrapper.module.css";
import NavContext from "../NavContext";

// TODO: split this out to be less of a weird state + animation handling component
export default ({ children }) => (
  <NavContext.Consumer>
    {({ reportNavOpen }) => (
      <React.Fragment>
        <div
          className={reportNavOpen ? styles.wrapperBgOpen : styles.wrapperBg}
        />
        <div
          className={
            reportNavOpen ? styles.wrapperRootOpen : styles.wrapperRoot
          }
        >
          {children}
        </div>
      </React.Fragment>
    )}
  </NavContext.Consumer>
);
