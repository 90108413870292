// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-chapter-js": () => import("/home/ubuntu/frontend_build/src/templates/chapter.js" /* webpackChunkName: "component---src-templates-chapter-js" */),
  "component---src-templates-article-js": () => import("/home/ubuntu/frontend_build/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-standalone-article-js": () => import("/home/ubuntu/frontend_build/src/templates/standaloneArticle.js" /* webpackChunkName: "component---src-templates-standalone-article-js" */),
  "component---src-templates-standalone-chart-js": () => import("/home/ubuntu/frontend_build/src/templates/standaloneChart.js" /* webpackChunkName: "component---src-templates-standalone-chart-js" */),
  "component---src-pages-404-js": () => import("/home/ubuntu/frontend_build/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("/home/ubuntu/frontend_build/src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("/home/ubuntu/frontend_build/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-articles-js": () => import("/home/ubuntu/frontend_build/src/pages/search-articles.js" /* webpackChunkName: "component---src-pages-search-articles-js" */),
  "component---src-pages-search-data-js": () => import("/home/ubuntu/frontend_build/src/pages/search-data.js" /* webpackChunkName: "component---src-pages-search-data-js" */),
  "component---src-pages-terms-js": () => import("/home/ubuntu/frontend_build/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

exports.data = () => import("/home/ubuntu/frontend_build/.cache/data.json")

