import { Highlight, Snippet } from "react-instantsearch-dom";
import { Link } from "gatsby";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import SaveContainer from "../../Save/container";

const HIT_MATCH = /^(full|partial)$/;

import styles from "./index.module.css";

const matchHitSnippet = (hit, attribute) => {
  const res = get(hit, `_snippetResult.${attribute}.matchLevel`, ``).match(
    HIT_MATCH
  );
  return res;
};

const MaybeSnippet = ({ hit, attribute }) => {
  if (!matchHitSnippet(hit, attribute)) return null;
  return (
    <p className={styles.snippet}>
      <Snippet attribute={attribute} hit={hit} />
    </p>
  );
};

MaybeSnippet.propTypes = {
  hit: PropTypes.shape({}),
  attribute: PropTypes.string
};

const Hit = ({ hit }) => {
  const titleMatch = get(hit, `_highlightResult.title.matchLevel`).match(
    HIT_MATCH
  );
  const anchorId = titleMatch
    ? `#article-${hit.entityId}`
    : `#block-${hit.entityId}-${hit.block.priority}`;
  return (
    <Link to={`/search-articles${anchorId}`} className={styles.root}>
      <div className={styles.save}>
        <SaveContainer entityId={hit.entityId} />
      </div>
      <p className={styles.titleNumber}>{hit.titleNumber}</p>
      <Highlight attribute="title" hit={hit} className={styles.title} />
      <MaybeSnippet attribute="block.text" hit={hit} />
      <MaybeSnippet attribute="block.title" hit={hit} />
      <MaybeSnippet attribute="block.subTitle" hit={hit} />
    </Link>
  );
};

Hit.propTypes = {
  hit: PropTypes.shape({})
};

export default Hit;
